.accordions{margin:0 0 40px;}
.accordion{background:$white;border:1px solid $gray-10;margin:0 0 10px;}
.accordion .accordion-toggle{line-height:2.4rem;}
.accordion .accordion-toggle a{
	color:$gray-2;
	display:block;
	font-size:1.8rem;
	padding:10px 40px 10px 20px;
	position:relative;
	text-decoration:none;
}
.accordion .accordion-toggle .icon{
	color:$primary;
	position:absolute;
	right:20px;
	top:10px;
	transition:$animate;
}
.accordion .accordion-content{
	border-top:1px solid $gray-10;
	display:none;}
	
.accordion .accordion-content .content{
	font-size:1.6rem;
	line-height:2.6rem;
	max-width:$max-width;
	padding:20px;
}
.accordion .accordion-content .content p{margin:0 0 10px;}
.accordion .accordion-content .content ul{margin:0 0 10px 30px;}
.accordion.opened{box-shadow:0 0 8px rgba(0,0,0,0.1);}
.accordion.opened .accordion-toggle{font-weight:700;}
.accordion.opened .accordion-toggle .icon{transform:rotate(45deg);}

.bg-img{
	left:0;
	height:100%;
	overflow:hidden;
	position:absolute;
	top:0;
	width:100%;
	z-index:0;
}
.bg-img img{height:100%;object-fit:cover;width:100%;}
.bg-img .center-bottom{object-position:center bottom;}
.bg-img .center-center{object-position:center center;}
.bg-img .center-top{object-position:center top;}
.bg-img .left-bottom{object-position:left bottom;}
.bg-img .left-center{object-position:left center;}
.bg-img .left-top{object-position:left top;}
.bg-img .right-bottom{object-position:right bottom;}
.bg-img .right-center{object-position:right center;}
.bg-img .right-top{object-position:right top;}

.body-copy{max-width:$max-width;}

.buttons-wrap{margin-left:-5px;margin-right:-5px;}
.buttons-wrap > a{margin:5px;}

.card-wrap{height:100%;padding:0 0 30px;}
.card-wrap .card-inner.clickable:hover,
.card-wrap .card-inner.clickable:focus{
	border-color:$primary-l1 !important;
	box-shadow:0 0 4px rgba(0,0,0,0.15);
}
.card-wrap .card-inner{
	background:$white;
	border:1px solid $gray-10;
	font-size:1.6rem;
	height:100%;
	line-height:2.2rem;
	padding:20px;
	position:relative;
	transition:$animate;
}
.card-wrap .content .h5,
.card-wrap .content h5{font-weight:500;}
.card-wrap .content p{margin:0 0 10px;}
.card-wrap .content > p:last-child{margin:0;}
.card-wrap .content .head{
	background:$primary-d1;
	color:$white;
	margin:-21px -21px 15px;
	padding:7px 20px;
}
.card-wrap .content .img-wrap{margin:-20px -20px 20px;}
.card-wrap .content .date{
	background:transparent;
	border:none;
	margin:-10px 0 15px;
	padding:0;
	width:100%;
}
.card-wrap .content .date .time{margin:10px 0 0;white-space:nowrap;}
.card-wrap .content .text-cta i{top:3px;}

.code-bar{
	background:$gray-12;
	border:1px solid $gray-10;
	border-bottom:0;
	font-family:$font-monospace;
	font-size:1.4rem;
	line-height:2.0rem;
	padding:10px 15px;
}
.code-bar .buttons{font-size:1.8rem;text-align:right;}

.crumbs{
	color:$gray-5;
	display:none;
	font-size:1.3rem;
	line-height:1.8rem;
	margin:-20px 0 40px;
}
.crumbs a{display:inline-block;text-decoration:none;}
.crumbs a:hover,
.crumbs a:focus{color:#000;}
.crumbs span{display:inline-block;margin:0 5px;}
.crumbs .active{margin:0;}
@media (min-width:$breakpoint-sm) {
	.crumbs{display:block;}
}

.date{
	background:$white;
	border:1px solid $gray-10;
	margin:0 0 20px;
	padding:15px;
	width:220px;
}
.date .day{font-size:4.6rem;line-height:4.6rem;}
.date .month{white-space:nowrap;}
.date .time{margin:15px 0 0;}


.docs-copy{max-width:$max-width;}
@media (min-width:$breakpoint-xl) {
	.docs-copy{margin:0 auto;max-width:$max-width;}
}

.favorite-toggle{color:$secondary;}
.favorite-toggle:hover,
.favorite-toggle:focus{text-decoration:none;}
.favorite-toggle i:last-child{display:none;}
.favorite-toggle.favorited i:first-child{display:none;}
.favorite-toggle.favorited i:last-child{display:inline;}

.filter-tags{margin:-5px -5px 20px;}
.filter-tags a{
	border:1px solid $gray-10;
	border-radius:2px;
	color:$gray-3;
	display:inline-block;
	font-size:1.4rem;
	line-height:2.4rem;
	margin:5px;
	padding:4px 35px 4px 10px;
	position:relative;
	text-decoration:none;
	transition:$animate;
}
.filter-tags a i{
	color:$primary;
	position:absolute;
	right:10px;
	top:9px;
}
.filter-tags a:hover,
.filter-tags a:focus{background:$gray-12;border-color:$gray-8;}

.form-filters{
	background:$gray-12;
	margin:0 0 30px;
	padding:20px 20px 12px;
}
.form-filters p{margin:0 0 8px;}

.form-search{
	background:$gray-12;
	margin:0 0 30px;
	padding:20px;
	position:relative;
}
.form-search form{position:relative;}
.form-search form input{background:$white;padding-left:20px;padding-right:44px;}
.form-search form button{
	background:transparent;
	border:none;
	height:44px;
	font-size:1.6rem;
	line-height:44px;
	padding:0;
	position:absolute;
	right:0;
	top:0;
	width:44px;
}

.form-search .toggles{font-size:1.4rem;line-height:1.6rem;margin:12px 0 -15px;}
.form-search .toggles a:last-chidl{margin-right:0;}
.form-search .toggles a{
	color:$gray-4;
	display:inline-block;
	margin:0 20px 10px 0;
	padding-left:20px;
	position:relative;
	white-space:nowrap;
}
.form-search .toggles a:before{
	font-family:'Font Awesome 6 Free';
	content:"\f111";
	left:0;
	position:absolute;
	top:0;
}
.form-search .toggles a.checked:before{content:"\f192";}

.form-search-type{margin:0 0 30px;position:relative;}
.form-search-type form{position:relative;}
.form-search-type form input{
	background:$white;
	height:50px;;
	padding:9px 50px 9px 20px;
}
.form-search-type form button{
	background:transparent;
	border:none;
	color:$primary-d1;
	height:50px;
	font-size:2.6rem;
	line-height:50px;
	padding:0;
	position:absolute;
	right:0;
	top:0;
	width:50px;
}

.form-search-type .message{
	background:$primary-l2;
	font-size:1.4rem;
	line-height:1.6rem;
	padding:12px 20px;
}
.form-search-type .message a:last-chidl{margin-right:0;}
.form-search-type .toggles{font-size:1.4rem;line-height:1.6rem;margin:12px 0 -15px;}
.form-search-type .toggles a:last-chidl{margin-right:0;}
.form-search-type .toggles a{
	color:$gray-4;
	display:inline-block;
	margin:0 20px 10px 0;
	padding-left:20px;
	position:relative;
	white-space:nowrap;
}
.form-search-type .toggles a:before{
	font-family:'Font Awesome 6 Free';
	content:"\f111";
	left:0;
	position:absolute;
	top:0;
}
.form-search-type .toggles a.checked:before{content:"\f192";}

.heading .h2, .heading h2{font-size:2.8rem;line-height:3.2rem;}

.img-wrap{position:relative;}
.img-wrap img{max-width:100%;}

@media (min-width:576px) {
	.img-float-left{float:left;margin-right:30px;max-width:50%;}
	.img-float-right{float:right;margin-left:30px;max-width:50%;}
}

.list-no-bull{list-style:none;margin:0 0 40px;}

.list-icons{list-style:none;margin:0 0 40px;}
.list-icons li{padding-left:50px;position:relative;}
.list-icons li > span:first-child{
	left:20px;
	position:absolute;
	top:0;
}

.listing{border-bottom:1px solid $gray-10;margin:0 0 30px;padding:0 0 20px;}
.listing p{margin:0 0 10px;}
.listing ul{margin-bottom:10px;}
.listing .content{max-width:$max-width;}
.listing .text-label{margin:0 0 5px;}
.listing .text-meta{margin:0 0 5px;}
.listing .text-title{
	font-size:1.9rem;
	font-weight:400;
	line-height:3.0rem;
	margin:0 0 10px;
}

.more-box{
	border:1px solid $gray-10;
	margin:0 0 10px;
	padding:20px 20px 0px;
	position:relative;
}
.more-box .more-toggle{
	display:block;
	font-size:2.0rem;
	height:44px;
	line-height:44px;
	overflow:hidden;
	position:absolute;
	right:0;
	text-align:center;
	text-decoration:none;
	top:-5px;
	width:44px;
}

.more-box .more-toggle:after{content:"\f067";font-family:'Font Awesome 6 Free';font-weight:900;}
.more-box .more-toggle.opened:after{content:"\f068";}
.more-box .content{position:relative;}
.more-box .content .h3,
.more-box .content h3{padding-right:50px;}
.more-box .row > div{position:static;}

@media (min-width:$breakpoint-md) {

	.more-box .content{position:static;}
	.more-box .more-toggle{right:15px;top:15px;}

}

.note-wrap{margin:0 0 40px;overflow:hidden;padding-left:5px;}
.note-wrap .heading{
	background:rgba(255,255,255,0.65);
	color:$black;
	padding:5px 20px 5px 35px;
	position:relative;
}
.note-wrap .heading .icon{
	left:10px;
	position:absolute;
	top:5px;
}
.note-wrap .content{
	background:rgba(255,255,255,0.9);
	padding:20px 20px 10px 35px;
}
.note-wrap .content p{margin:0 0 10px;}

.notice-wrap{border-radius:4px;margin:0 0 40px;overflow:hidden;padding:2px;}
.notice-wrap .notice-inner{padding:10px 20px;}

.page-links{position:relative;margin:0;}
.page-links:before{
	background:$gray-10;
	bottom:15px;
	content:" ";
	position:absolute;
	top:15px;
	width:1px;
	z-index:1;
}
.page-links ul{
	font-size:1.4rem;
	line-height:1.8rem;
	list-style:none;
	margin:0;
}
.page-links ul li{margin:0;}
.page-links ul li a{
	color:$gray-3;
	display:block;
	padding:5px 15px;
	position:relative;
	text-decoration:none;
}
.page-links ul li a:hover,
.page-links ul li a:focus{background:$gray-12;}
.page-links ul li ul li a{padding-left:35px;}
.page-links ul li ul li ul li a{padding-left:55px;}
.page-links ul li.active > a{color:$primary;font-weight:700;}
.page-links ul li.active > a:after{
	background:$primary;
	border-radius:100%;
	content:" ";
	height:8px;
	left:-3px;
	position:absolute;
	top:11px;
	width:8px;
	z-index:2;
}
@media (min-width:$breakpoint-xl) {
	.page-links{margin:0 -30px 0 0;}
	.page-links{max-width:260px;}

}
@media (min-width:1500px) {
	.page-links{max-width:350px;}
}

.page-links-dropdown{
	font-size:1.4rem;
	line-height:1.8rem;
	margin:0 0 30px;
	position:relative;
}
.page-links-dropdown .head{position:relative;}
.page-links-dropdown .head a{
	border:1px solid $primary;
	color:$gray-3;
	display:block;
	font-weight:700;
	padding:12px 40px 12px 15px;
	text-decoration:none;
}
.page-links-dropdown .head .text i{font-weight:900;margin-right:5px;}
.page-links-dropdown .head a .icons{color:$primary;position:absolute;right:20px;top:13px;}
.page-links-dropdown .head a .icons i:last-child{display:none;}
.page-links-dropdown .head a.opened .icons i:first-child{display:none;}
.page-links-dropdown .head a.opened .icons i:last-child{display:inline;}
.page-links-dropdown > ul{
	background:$white;
	border:1px solid $primary;
	border-top:none;
	box-shadow:0 2px 4px rgba(0,0,0,0.2);
	display:none;
	font-size:1.4rem;
	line-height:2.2rem;
	list-style:none;
	left:0;
	margin:0;
	padding:10px 0;
	position:absolute;
	right:0;
	top:100%;
	z-index:1;
}

.page-links-dropdown > ul ul{list-style:none;margin:0;}
.page-links-dropdown ul li{margin:0;}
.page-links-dropdown ul li a{
	color:$gray-3;
	display:block;
	padding:5px 15px;
	position:relative;
	text-decoration:none;
}
.page-links-dropdown ul li a:hover,
.page-links-dropdown ul li a:focus{background:$gray-12;}
.page-links-dropdown ul li ul li a{padding-left:35px;}
.page-links-dropdown ul li ul li ul li a{padding-left:55px;}


.page-tools{font-size:1.8rem;line-height:1.8rem;margin:-20px 0 40px;}
.page-tools a{color:$gray-3;margin-right:15px;}
.page-tools a:last-child{margin-right:0;}

.pager{margin:0 -3px 30px;text-align:center;}
.pager nav a{
	background:$white;
	border:1px solid $gray-10;
	border-radius:2px;
	color:$gray-3;
	display:inline-block;
	font-size:1.4rem;
	line-height:32px;
	margin:2px 0;
	min-width:34px;
	text-align:center;
	transition:$animate;
}
.pager nav a:hover,
.pager nav a:focus{
	background:$gray-12;
	border-color:$gray-8;
	text-decoration:none;
}
.pager nav a:focus{background:$primary;color:$white;}
.pager nav span{
	display:inline-block;
	line-height:32px;
	text-align:center;
	width:34px;
}
.pager nav .on{background:$gray-11;}
.pager nav .on:hover,
.pager nav .on:focus{background:$gray-11;color:$gray-3;text-decoration:none;}
.pager-prev-next{font-size:1.8rem;line-height:2.4rem;}
.pager-prev-next a{display:block;position:relative;}
.pager-prev-next a i{
	margin-top:-9px;
	position:absolute;
	top:50%;
	transition:$animate;
}
.pager-prev-next a:hover,
.pager-prev-next a:focus{text-decoration:none;}
.pager-prev-next .prev{padding-left:20px;text-align:left;}
.pager-prev-next .prev i{left:0;}
.pager-prev-next .prev:hover i,
.pager-prev-next .prev:focus i{left:-2px;}
.pager-prev-next .next{padding-right:20px;text-align:right;}
.pager-prev-next .next i{right:0;}
.pager-prev-next .next:hover i,
.pager-prev-next .next:focus i{right:-2px;}

.pdf-wrap{overflow:hidden;padding-bottom:135%;position:relative;}
.pdf-wrap object{
	border:none;
	bottom:0;
	left:0;
	position:absolute;
	right:0;
	top:0;
}

.side-filters{border:1px solid $gray-10;margin:0 0 30px;}
.side-filters .toggle{
	background:$white;
	color:$gray-2;
	display:block;
	line-height:2.4rem;
	padding:10px 40px 10px 20px;
	position:relative;
	text-decoration:none;
}
.side-filters .toggle .icon{
	color:$primary;
	position:absolute;
	right:15px;
	top:10px;
}
.side-filters .toggle .icon{transition:$animate;}
.side-filters .toggle.opened .icon{transform:rotate(45deg);}

.side-filters a{text-decoration:none;}
.side-filters hr{margin:0 0 15px;}
.side-filters p{margin:0 0 5px;}
.side-filters ul{list-style:none;margin:0 4px 4px;}
.side-filters ul li{border-top:1px solid $gray-10;margin:0;position:relative;}
.side-filters ul li a{
	color:$gray-3;
	display:block;
	line-height:2.0rem;
	margin-right:30px;
	padding:5px 5px 5px 23px;
	position:relative;
}
.side-filters ul li a:before{
	color:$gray-3;
	font-family:'Font Awesome 6 Pro';
	font-size:1.3rem;
	content:"\f0c8";
	left:2px;
	position:absolute;
	top:5px;
}
.side-filters ul li a.checked:before{content:"\f14a";}

.side-filters ul li label{align-items:flex-start;display:flex;padding:5px 0;}
.side-filters ul li label input{
	height:auto;
	margin:0 10px 0 0;
	box-shadow:none;
	padding:0;
	position:relative;
	top:3px;
	width:auto;
}
.side-filters ul li label .label-text{font-size:1.4rem;margin:0 30px 0 0;}
.side-filters .content ul > li > ul > li > ul > li > ul > li > label{padding:3px 0;}

.side-filters .checklist-radios{border-top:1px solid $gray-10;padding:5px 0 10px;}
.side-filters .checklist-radios li{border-top:none;}
.side-filters .checklist-radios li a.radio{margin-right:0;}
.side-filters .checklist-radios li a.radio:before{content:"\f111";}
.side-filters .checklist-radios li a.radio.checked:before{content:"\f192";}

.side-filters ul li a.toggle-list:before{display:none;}
.side-filters ul li a.toggle-list{
	color:$black;
	font-size:1.4rem;
	height:30px;
	line-height:32px;
	margin:0;
	padding:0;
	position:absolute;
	right:0;
	text-align:center;
	top:0;
	width:30px;
}
.side-filters ul li a.toggle-list .icon{transition:$animate;}
.side-filters ul li a.toggle-list.opened .icon{display:block;transform:rotate(45deg);}

.side-filters .content ul > li > ul{margin:0 0 0 20px;}
.side-filters .content ul > li > ul > li > ul > li > ul{margin-bottom:8px;}
.side-filters .content ul > li > ul > li > ul > li > ul > li{border-top:none;}
.side-filters .content ul > li > ul > li > ul > li > ul > li > a{padding:3px 5px 3px 23px;}
.side-filters .content ul > li > ul > li > ul > li > ul > li > a:before{top:3px;}
.side-filters .side-filter{
	border-bottom:3px solid $gray-10;
	margin:0 0 15px;
}
.side-filters .toggle-filters a{
	color:$gray-3;
	display:block;
	line-height:2.4rem;
	outline:none;
	padding:5px 40px 5px 0;
	position:relative;
	text-decoration:none;
	text-transform:uppercase;
}
.side-filters .toggle-filters .icon{
	color:$primary;
	position:absolute;
	right:11px;
	top:5px;
}
.side-filters .toggle-filters .icon{transition:$animate;}
.side-filters .toggle-filters.opened .icon{transform:rotate(45deg);}

.side-filters .content{
	border-top:1px solid $gray-10;
	display:none;
	padding:15px 20px 10px;
}
.side-filters .heading{margin:0 0 5px;}
@media (min-width:$breakpoint-md) {

	.side-filters{border:none;}
	.side-filters .toggle{display:none;padding:10px 0 0 2px;pointer-events:none;}
	.side-filters .toggle-filters a{display:block;padding-left:2px}
	.side-filters .content{border-top:none;display:block !important;padding:0;}
	.side-filters .content ul{margin:0 4px 4px;}

}

.slider-wrap{padding:0 50px;}
.slider-wrap .slick-slider{margin:0 -15px 30px;}
.slider-wrap .slick-slider .card-wrap{height:auto;padding:0 15px;}
.slider-wrap .slick-slider .img-wrap img{width:100%;}

.table-wrap{margin:0 0 30px;overflow:auto;}
.table-wrap table{margin:0;}
.table-responsive .inline-heading{display:none;}
@media (max-width: 767px) {
	.table-responsive{border:1px solid $gray-11;border-bottom:none;}
	.table-responsive thead{display:none;}
	.table-responsive tbody tr:nth-child(odd) td{background:$white;border-bottom:1px solid $gray-12;}
	.table-responsive tbody tr:nth-child(even) td{background:$gray-12;border-bottom:1px solid $white;}
	.table-responsive tbody tr td:last-child{border-bottom:1px solid $gray-11;}
	.table-responsive tbody td{
		background:$white;
		border:none;
		display:flex;
		justify-content:flex-start;
	}
	.table-responsive tbody td .inline-heading{
		display:flex;
		font-weight:600;
		margin-right:15px;
		min-width:100px;
	}
}

.tabs{margin:0 0 30px;}
.tabs .tab-nav{display:none;margin-bottom:-1px;position:relative;z-index:1;}
.tabs .tab-nav a{
	align-items:center;
	background:$gray-12;
	border:1px solid $gray-10;
	color:$primary-d1;
	display:flex;
	line-height:2.0rem;
	margin-right:5px;
	outline:none;
	padding:10px 20px;
	position:relative;
	text-align:center;
	text-decoration:none;
	transition:$animate;
}
.tabs .tab-nav a:last-child{margin-right:0;}
.tabs .tab-nav a.on:after{
	background:$primary;
	content:" ";
	height:5px;
	left:-1px;
	position:absolute;
	right:-1px;
	top:-1px;
}
.tabs .tab-nav a.on,
.tabs .tab-nav a:hover,
.tabs .tab-nav a:focus{
	background:$white;
	border-bottom-color:$white;
	color:$gray-3;
	text-decoration:none;
}
.tabs .tab-content{background:$white;border:1px solid $gray-10;}
.tabs .tab-content .tab-bar{
	background:$gray-12;
	border-bottom:1px solid $gray-10;
	color:$primary;
	display:block;
	outline:none;
	padding:10px 30px;
	position:relative;
	text-decoration:none;
	transition:$animate;
}
.tabs .tab-content .tab-bar:last-of-type{border-bottom:1px solid $white;}
.tabs .tab-content .tab-bar.on{
	background:$white;
	border-bottom:1px solid $gray-10;
	color:$gray-3;
}
.tabs .tab-content .tab-bar.on:after{
	background:$primary;
	bottom:-1px;
	content:" ";
	left:-1px;
	position:absolute;
	top:-1px;
	width:5px;
}
.tabs .tab-content .tab{padding:30px 30px 15px;}
.tabs .tab-content .tab p{margin:0 0 15px;}

@media (min-width:$breakpoint-lg) {
	.tabs .tab-nav{display:flex;}
	.tabs .tab-content .tab-bar{display:none;}
}

.tip-wrap{position:relative;}
.tip-wrap .tip-text{border-bottom:2px dotted $primary;cursor:help;}
.tip-wrap .tip-content{
	background:rgba($primary, 0.80);
	box-shadow:0 0 8px rgba(0,0,0,0.4);
	border-left:5px solid $primary;
	bottom:19px;
	display:none;
	color:$white;
	font-size:1.3rem;
	left:50%;
	margin-left:-100px;
	line-height:1.8rem;
	position:absolute;
	padding:10px;
	width:200px;
}
.tip-wrap:hover .tip-content,
.tip-wrap:focus .tip-content{display:block;}

.vid-wrap{overflow:hidden;padding-bottom:56.25%;position:relative;}
.vid-wrap iframe{border:none;bottom:0;left:0;height:100%;position:absolute;right:0;top:0;width:100%;}