/*
====================================================================================================
Plugins
====================================================================================================
*/

/* Prism JS */
pre:before,
pre:after{display:none !important;}
pre{margin:0 0 30px !important;}
pre > code{border-left-color:$primary-d1 !important;}

/* Slick Slider */
.slick-slider{display:none !important;}
.slick-slider.slick-initialized{display:block !important;}

.slick-track{display:flex !important;}
.slick-track .slick-slide{display:flex !important;height:auto !important;}

.slick-arrow{
	background:$gray-8;
	border:none;
	cursor:pointer;
	height:44px;
	line-height:44px;
	margin-top:-22px;
	overflow:hidden;
	outline:none;
	padding:0;
	position:absolute;
	text-indent:-999px;
	top:50%;
	width:36px;
	z-index:1;
}
.slick-arrow:after{
	color:$white;
	font-family:"Font Awesome 6 Free";
	font-size:2.0rem;
	font-weight:900;
	position:absolute;
	text-indent:0;
}
.slick-arrow.slick-prev{left:-36px;}
.slick-arrow.slick-prev:after{content:"\f053";left:12px;}
.slick-arrow.slick-next{right:-36px;}
.slick-arrow.slick-next:after{content:"\f054";right:12px;}
.slick-arrow:focus{outline:2px dotted $primary;}

.slick-dots{
	line-height:20px;
	list-style:none;
	margin:0;
	text-align:center;
	width:100%;
	z-index:1;
}
.slick-dots li{display:inline-block;margin:0 5px 0;}
.slick-dots li button{
	background:transparent;
	border:2px solid $primary;
	border-radius:100%;
	cursor:pointer;
	height:18px;
	overflow:hidden;
	padding:0;
	text-indent:-1000px;
	width:18px;
}
.slick-dots li.slick-active button{background:$primary;border-color:$primary;}

/* Popups */
.mfp-bg{background:$black;opacity:0.5;}
.white-popup{
	background:$white;
	box-shadow:2px 8px 16px 0 rgba(0,0,0,0.25);
	font-size:1.6rem;
	line-height:2.6rem;
	margin-left:auto;
	margin-right:auto;
	max-width:700px;
	position:relative;
}
.white-popup .mfp-close{
	height:40px;
	font-size:3.0rem;
	line-height:4.0rem;
	opacity:1;
	position:absolute;
	padding:0;
	right:0;
	text-align:center;
	top:0;
	width:40px;
}
.white-popup .mfp-close:hover,
.white-popup .mfp-close:focus{background:transparent;}
.white-popup .content{padding:30px;}