/*
====================================================================================================
#Page
====================================================================================================
*/

#page{display:flex;flex-direction:column;min-height:100%;}
#page main{
	flex-grow:1;
	flex-shrink:0;
	position:relative;
	transition:$animate;
}

@media (min-width:$breakpoint-md) {

	#page .side-stick{position:sticky;top:0;z-index:1;}

}

/*
====================================================================================================
Docs Layout
====================================================================================================
*/

/* Docs Layout */

#page.layout-docs{padding:0;}
#page.layout-docs header{border-bottom:none;height:auto;position:sticky;}
#page.layout-docs header:before{display:none;}
#page.layout-docs footer .footer-content{transition:$animate;}
#page.layout-docs .tray-alert .alert-content{transition:$animate;}
@media (min-width:$breakpoint-xl) {

	#page.layout-docs main{padding-left:350px;}
	#page.layout-docs footer .footer-content{padding-left:350px;}
	#page.layout-docs .tray-alert .alert-content{padding-left:350px;}

	#page.layout-docs.toc-closed .toc{max-width:56px;}
	#page.layout-docs.toc-closed .toc .toc-content{opacity:0;}
	#page.layout-docs.toc-closed .toc .toc-tabs{left:auto;right:0;}
	#page.layout-docs.toc-closed main{padding-left:20px;}
	#page.layout-docs.toc-closed footer .footer-content{padding-left:20px;}
	#page.layout-docs.toc-closed .tray-alert .alert-content{padding-left:20px;}

}

/*
====================================================================================================
Header
====================================================================================================
*/
header.opened-search .header-content .dropdowns > ul > li > .dropdown,
header.opened-search .header-content .dropdowns > ul > li > ul{display:none !important;}
header{
	background:$white;
	border-bottom:5px solid $secondary;
	left:0;
	position:sticky;
	top:0;
	width:100%;
	z-index:2;
}
header:before{
	background: $secondary-d2;
	background: -moz-linear-gradient(left,  $secondary-d2 0%, $secondary 100%);
	background: -webkit-linear-gradient(left,  $secondary-d2 0%,$secondary 100%);
	background: linear-gradient(to right,  $secondary-d2 0%,$secondary 100%);
	bottom:-5px;
	content:" ";
	height:5px;
	left:0;
	position:absolute;
	width:100%;
}


header .alert-content{
	background:$black;
	bottom:0;
	color:$white;
	font-size:1.6rem;
	line-height:2.2rem;
	padding:24px 0;
	position:sticky;
	left:0;
	width:100%;
	z-index:2;
}
header .alert-content p{margin:0;}
header .alert-content .container{position:relative;}
header .alert-content .content{display:flex;padding-right:40px;}
header .alert-content .content .icon{font-size:3.0rem;margin:2px 15px 0 0;}
header .alert-content .close-alert{
	color:$white;
	display:inline-block;
	padding:5px 10px;
	position:absolute;
	right:20px;
	text-decoration:none;
	top:-3px;
}

header .header-content{padding:10px 0;position:relative;}
header .header-content .container{position:relative;}
header .header-content .container-fluid{position:relative;}
header .header-content .skip-to-content{
	background:$primary;
	border:2px solid $primary;
	color:$white;
	display:inline-block;
	font-size:1.6rem;
	left:-2000%;
	line-height:20px;
	margin-top:-20px;
	min-width:160px;
	padding:8px 20px;
	position:absolute;
	text-decoration:none;
	text-align:center;
	top:50%;
	z-index:2;
}
header .header-content .skip-to-content:focus{left:auto;right:15px;}
header .header-content .logo{display:inline-block;}
header .header-content .logo img{height:60px;}
header .header-content .dropdowns{
	display:none;
	margin-top:-24px;
	position:absolute;
	right:130px;
	top:50%;
}
header .header-content .dropdowns > ul{
	display:flex;
	font-size:1.5rem;
	line-height:2.0rem;
	list-style:none;
	margin:0;
	z-index:1;
}
header .header-content .dropdowns > ul > li{
	margin:0;
	padding:0 2px;
	position:relative;
}
header .header-content .dropdowns > ul > li > a{
	color:$black;
	display:block;
	padding:14px 15px;
	text-decoration:none;
	transition:$animate;
}
header .header-content .dropdowns > ul > li:hover > a,
header .header-content .dropdowns > ul > li:focus > a,
header .header-content .dropdowns > ul > li.focused > a{color:$primary-d1;text-decoration:underline;}
header .header-content .dropdowns > ul > li:hover > ul,
header .header-content .dropdowns > ul > li:focus > ul,
header .header-content .dropdowns > ul > li.focused > ul{height:auto;opacity:1;width:220px;}
header .header-content .dropdowns > ul > li > ul{
	background:$white;
	box-shadow:0 3px 7px rgba(0,0,0,0.10);
	height:0;
	list-style:none;
	left:50%;
	margin:0;
	margin-left:-110px;
	opacity:0;
	overflow:hidden;
	position:absolute;
	text-align:center;
	top:100%;
	width:0;
}
header .header-content .dropdowns > ul > li > ul > li:first-child{margin-top:15px;}
header .header-content .dropdowns > ul > li > ul > li:last-child{margin-bottom:15px;}
header .header-content .dropdowns > ul > li > ul > li{margin:0;}
header .header-content .dropdowns > ul > li > ul > li > a{
	color:$black;
	display:block;
	padding:6px 20px;
	text-decoration:none;
	transition:$animate;
}
header .header-content .dropdowns > ul > li > ul > li > a:hover,
header .header-content .dropdowns > ul > li > ul > li > a:focus{
	background:$gray-12;
	color:black;
}

header .header-content .links{
	font-size:2.4rem;
	line-height:48px;
	margin-top:-24px;
	position:absolute;
	right:10px;
	top:50%;
}
header .header-content .links > a{
	color:$primary-d1;
	display:inline-block;
	height:48px;
	line-height:48px;
	margin:0 0 0 10px;
	padding:0;
	text-align:center;
	text-decoration:none;
	width:48px;
}
header .header-content .links > a:hover,
header .header-content .links > a:focus{color:$black;}

header .header-content .links .open-menu i:last-child{display:none;}
header .header-content .links .open-menu.opened i:first-child{display:none;}
header .header-content .links .open-menu.opened i:last-child{display:inline;}
header .header-content .links .link-login{display:none;}
header .header-content .links .open-search{display:none;}
header .header-content .links .user-menu{
	display:none;
	margin:0;
	position:relative;
	z-index:1;
}
header .header-content .links .user{
	color:$primary-d1;
	display:inline-block;
	height:48px;
	line-height:48px;
	margin:0 0 0 10px;
	padding:0;
	text-decoration:none;
	text-align:center;
	width:48px;
}
header .header-content .links .user:hover,
header .header-content .links .user:focus{color:$black;}
header .header-content .links .user i:last-child{font-size:1.8rem;}
header .header-content .links .user-links{
	background:$white;
	border:1px solid $gray-10;
	box-shadow:0 0 10px rgba(0,0,0,0.1);
	display:none;
	font-size:1.5rem;
	line-height:1.8rem;
	position:absolute;
	right:0;
	top:54px;
	width:275px;
}
header .header-content .links .user-links span{
	background:$gray-12;
	color:$black;
	display:block;
	font-size:1.8rem;
	margin:0;
	padding:10px 20px;
}
header .header-content .links .user-links span i{margin-right:5px;}
header .header-content .links .user-links a{
	border-top:1px solid $gray-10;
	color:$primary-d2;
	display:block;
	margin:0;
	padding:10px 20px;
}
header .header-content .links .user-links a:hover,
header .header-content .links .user-links a:focus{
	background:$gray-12;
	color:$black;
	text-decoration:none;
}

@media (min-width:$breakpoint-lg) {

	header .header-content{text-align:left;}
	header .header-content .dropdowns{display:flex;}
	header .header-content .links{font-size:2.0rem;}
	header .header-content .links .link-login{display:inline-block;}
	header .header-content .links .open-search{display:inline-block;}
	header .header-content .links .open-menu{display:none;}
	header .header-content .links .user-menu{display:inline-block;}

}
@media (min-width:$breakpoint-xl) {

	header .header-content .open-toc{display:none;}
	header .header-content .dropdowns > ul > li{padding:0 10px;}

}

header .search-content{
	background:$white;
	border-bottom:5px solid $slate;
	border-top:1px solid $gray-10;
	display:none;
	left:0;
	padding:10px 0;
	position:absolute;
	top:100%;
	width:100%;
	z-index:1;
}
header .search-content .search{
	margin:0 auto 10px;
	max-width:720px;
	padding-right:60px;
	position:relative;
}
header .search-content .search input{
	border:none;
	border-bottom:2px solid $gray-11;
	height:52px;
	font-size:1.8rem;
	padding-left:0;
	padding-right:55px;
}
header .search-content .search input:focus{box-shadow:none;}
header .search-content .search button{
	background:transparent;
	border:none;
	color:$primary;
	font-size:3.0rem;
	height:52px;
	line-height:52px;
	position:absolute;
	right:60px;
	top:0;
	width:52px;
}
header .search-content .search .close-search{
	display:block;
	height:48px;
	line-height:48px;
	font-size:1.8rem;
	margin-top:-24px;
	position:absolute;
	right:0;
	text-align:center;
	text-decoration:none;
	top:50%;
	width:48px;
}
@media (min-width:$breakpoint-sm) {
	header .search-content .search input{font-size:2.0rem;}
}
header .search-content .search-suggestions{margin:0 auto 10px;max-width:720px;}
header .search-content .search-suggestions ul{list-style:none;margin:0;}
header .search-content .search-suggestions ul li a{color:$gray-3;}

header .banner-content{padding:14px 0 14px 45px;position:relative;}
header .banner-content .h1, header .banner-content h1{margin:0;}
header .banner-content .open-toc{
	color:$white;
	display:block;
	font-size:2.4rem;
	line-height:48px;
	height:48px;
	left:10px;
	margin-top:-24px;
	position:absolute;
	text-align:center;
	text-decoration:none;
	top:50%;
	width:48px;
}
header .banner-content .open-toc:hover,
header .banner-content .open-toc:focus{color:$white;}
header .banner-content .open-toc i:last-child{display:none;}
header .banner-content .open-toc.opened i:first-child{display:none;}
header .banner-content .open-toc.opened i:last-child{display:inline;}

@media (min-width:$breakpoint-xl) {

	header .banner-content{padding-left:0;}
	header .banner-content .open-toc{display:none;}

}

/*
====================================================================================================
Main
====================================================================================================
*/

main{position:relative;}
main .content-inner{margin:0 auto;padding:20px 20px 0;}
main .content-inner .crumbs{margin:0 0 20px;}
main .content-inner .page-tools{font-size:1.6rem;margin:0 0 20px;}

@media (min-width:$breakpoint-xl) {
	main .content-inner{padding:20px 30px 0;}
}

/*
====================================================================================================
Footer
====================================================================================================
*/

footer{color:$white;font-size:1.5rem;line-height:2.1rem;}
footer p{margin:0 0 15px;}
footer ul{list-style:none;margin:0;}
footer a{color:$white;}
footer a:hover,
footer a:focus{color:$white;}
footer .footer-content{background:$slate;padding:40px 0 25px;}
footer .footer-content:last-child{background:$slate-d1;padding:50px 0 35px;}

footer .links-toggle{
	background:rgba(0,0,0,0.2);
	border:1px solid $white;
	display:block;
	font-size:1.6rem;
	line-height:2.4rem;
	margin:15px 0;
	padding:9px 40px 9px 15px;
	position:relative;
	text-decoration:none;
	text-transform:uppercase;
}
footer .links-toggle .icons{position:absolute;right:15px;top:9px;}
footer .links-toggle .icons i:last-child{display:none;}
footer .links-toggle.opened .icons i:first-child{display:none;}
footer .links-toggle.opened .icons i:last-child{display:inline;}
footer .links-wrap{display:none;margin:0 0 15px;padding:0 15px;}
footer .logo{display:block;margin:0 auto 30px;max-width:240px;}
footer .logo img{max-width:100%;}
footer .social{margin:-20px -5px 15px;text-align:center;}
footer .social a{
	background:$white;
	border-radius:100%;
	color:$primary;
	display:inline-block;
	font-size:2.0rem;
	height:36px;
	line-height:3.6rem;
	margin:5px;
	width:36px;
}
footer .legal{margin:0 0 15px;text-align:center;}
footer .copyright{font-size:1.4rem;line-height:2.0rem;text-align:center;}

@media (min-width:$breakpoint-sm) {

	footer .links-toggle{display:none;}
	footer .links-wrap{display:block !important;padding:0;}

}

@media (min-width:$breakpoint-lg) {

	footer .logo{margin:0;}

}

/*
====================================================================================================
Menu
====================================================================================================
*/

.menu.opened{right:0;width:100%;}
.menu{
	background:$white;
	border-left:1px solid $gray-10;
	bottom:0;
	max-width:430px;
	overflow:auto;
	position:fixed;
	right:-430px;
	transition:right 0.2s ease-in-out;
	top:65px;
	width:0;
	z-index:2;
}
.menu a{color:$gray-3;}

.menu .form-search{margin:0;padding:0;}
.menu .form-search input{
	background:$gray-12;
	border-left:none;
	border-right:none;
	box-shadow:none;
}

.menu .main ul{
	font-size:1.6rem;
	line-height:2.2rem;
	list-style:none;
	margin:0;
}
.menu .main ul > li{
	border-bottom:1px solid $gray-10;
	margin:0;
	overflow:hidden;
	position:relative;
}
.menu .main ul > li > a{
	display:block;
	padding:8px 40px 8px 20px;
	transition:$animate;
}
.menu .main ul > li > a:hover,
.menu .main ul > li > a:focus{background:$gray-11;text-decoration:none;}
.menu .main ul > li > ul{margin:0;}
.menu .main ul > li > .toggle-sub-list{
	background:transparent;
	border:none;
	color:$gray-3;
	font-size:2.2rem;
	outline:none;
	padding:8px 5px;
	position:absolute;
	right:0;
	text-align:center;
	top:0;
	width:32px;
}
.menu .main ul > li > .toggle-sub-list:hover,
.menu .main ul > li > .toggle-sub-list:focus{background:transparent;}
.menu .main ul > li.opened > .toggle-sub-list{transform:rotate(90deg);}

.menu .main ul > li > .sub-list ul li{border-bottom:none;}
.menu .main ul > li > .sub-list ul li a{padding-left:40px;}
.menu .main ul > li > .sub-list ul li a:hover,
.menu .main ul > li > .sub-list ul li a:focus{text-decoration:none;}
.menu .main ul > li > .sub-list .sub-list-item{border-top:1px solid $gray-10;}
.menu .main ul > li > .sub-list .sub-list-head{padding:8px 40px 8px 20px;position:relative;}
.menu .main ul > li > .sub-list .sub-list-head a{
	border:none;
	color:$primary;
	outline:none;
	padding:8px 5px;
	position:absolute;
	right:0;
	text-align:center;
	top:0;
	transition:$animate;
	width:32px;
}
.menu .main ul > li > .sub-list .sub-list-head a.opened{transform:rotate(45deg);}

.menu .foot ul{
	font-size:1.4rem;
	line-height:2.0rem;
	list-style:none;
	margin:0;
	padding:20px 0;
}
.menu .foot ul li{margin:0;}
.menu .foot ul li > a{display:block;padding:5px 50px;}
.menu .foot ul li > span{
	display:block;
	font-size:1.6rem;
	font-weight:600;
	padding:10px 50px;
	position:relative;
	text-transform:uppercase;
}
.menu .foot ul li > span i{
	font-size:2.0rem;
	left:15px;
	position:absolute;
	top:10px;
}

/*
====================================================================================================
TOC
====================================================================================================
*/

.toc.opened{left:0;}
.toc{
	bottom:0;
	left:-430px;
	max-width:430px;
	position:fixed;
	top:0;
	transition:left 0.2s ease-in-out;
	width:100%;
	z-index:3;
}

.toc .toc-inner{
	background:$white;
	bottom:0;
	left:0;
	overflow:hidden;
	position:absolute;
	right:0;
	top:0;
}

.toc .toc-tabs{display:flex;}
.toc .toc-tabs a{
	background:$gray-12;
	color:$gray-3;
	flex:1;
	font-weight:500;
	line-height:2.4rem;
	padding:10px 5px;
	position:relative;
	text-align:center;
	text-decoration:none;
	text-transform:uppercase;
}
.toc .toc-tabs a .icon{
	color:$primary-l1;
	display:none;
	font-size:2.6rem;
}
.toc .toc-tabs a.on{
	background:$white;
	color:$black;
	font-weight:700;
}
.toc .toc-tabs a.on:after{
	background:$primary;
	bottom:0;
	content:" ";
	height:4px;
	left:0;
	position:absolute;
	width:100%;
}

.toc .heading{
	background:$gray-11;
	border-right:1px solid $gray-10;
	color:$black;
	display:none;
	font-weight:500;
	line-height:2.4rem;
	padding:10px 50px 10px 20px;
	position:relative;
	text-transform:uppercase;
}
.toc .heading .close-toc{
	font-size:2.0rem;
	height:44px;
	line-height:44px;
	position:absolute;
	right:0;
	text-align:center;
	top:0;
	width:44px;
}

.toc .form-search{margin:10px 15px;padding:0;}
.toc .form-search input{
	background:$white;
	box-shadow:none;
	height:60px;
	padding:14px 10px
}
.toc .form-search button{height:60px;}

.toc .content .search-suggestions ul{border-top:none;margin:0 0 20px;}
.toc .content .search-suggestions ul > li > span,
.toc .content .search-suggestions ul > li > a{
	color:$gray-3;
	display:block;
	padding:5px 15px;
}

.toc .content{padding:0;}
.toc .content > ul{border-top:1px solid $gray-10;}
.toc .content > ul > li{border-bottom:1px solid $gray-10;}
.toc .content ul{
	font-size:1.6rem;
	line-height:2.2rem;
	list-style:none;
	margin:0;
}
.toc .content ul > li{
	margin:0;
	position:relative;
	transition:$animate;
}
.toc .content ul > li > a{
	color:$gray-3;
	display:block;
	padding:8px 20px;
	text-decoration:none;
	transition:$animate;
}
.toc .content ul > li > a:hover,
.toc .content ul > li > a:focus{background:$gray-12;}
.toc .content ul > li.parent > a{padding-right:40px;}
.toc .content ul > li.parent > .toggle{
	background:transparent;
	border:none;
	color:$gray-3;
	font-size:2.2rem;
	outline:none;
	padding:8px 5px;
	position:absolute;
	right:0;
	text-align:center;
	top:0;
	width:32px;
}
.toc .content ul > li.parent > .toggle i{transition:$animate;}
.toc .content ul > li.parent.opened{background:$white;}
.toc .content ul > li.parent.opened > .toggle i{transform:rotate(90deg);}
.toc .content ul > li > ul{background:transparent;}
.toc .content ul > li > ul > li > a{padding-left:40px;}
.toc .content ul > li > ul > li > ul > li > a{padding-left:60px;}
.toc .content ul > li > ul > li > ul > li > ul > li > a{padding-left:80px;}
.toc .content ul > li > ul > li > ul > li > ul > li > ul > li > a{padding-left:100px;}
.toc .content .on > a{color:$primary-d1;}
.toc .content .on > a:hover,
.toc .content .on > a:focus{background:darken($gray-12, 4%)}

.toc .toc-content .content{
	background:$white;
	border-right:1px solid $gray-10;
	bottom:0;
	left:0;
	overflow:auto;
	position:absolute;
	right:0;
	top:44px;
	transition:$animate;
}

@media (min-width:$breakpoint-xl) {

	.toc{left:0;max-width:350px;padding-left:56px;z-index:1;}
	.toc .toc-inner{background:transparent;padding-left:56px;}
	.toc .toc-tabs{
		background:$gray-2;
		bottom:0;
		left:0;
		position:absolute;
		top:0;
		width:56px;
		z-index:1;
	}
	.toc .toc-tabs{
		display:block;
		text-align:center;
	}
	.toc .toc-tabs a:after{display:none;}
	.toc .toc-tabs a{
		background:transparent;
		display:inline-block;
		line-height:44px;
		margin:0 0 10px;
		outline:none;
		padding:0;
		width:44px;
	}
	.toc .toc-tabs a .icon{display:inline;}
	.toc .toc-tabs a .text{display:none;}
	.toc .toc-tabs a.on{background:transparent;color:$white;}
	.toc .toc-tabs a.on .icon{color:$white;}
	.toc .heading{display:block;border-bottom:1px solid $gray-10;}
	.toc .toc-content .content{left:56px;top:45px;}
	.toc .content > ul{border-top:none;}

}

/*
====================================================================================================
Top
====================================================================================================
*/

.top-target{
	background:rgba(0,0,0,0.3);
	border-radius:5px 0 0 5px;
	bottom:100px;
	color:$white;
	display:none;
	font-size:2.0rem;
	height:46px;
	line-height:46px;
	position:fixed;
	right:0;
	text-align:center;
	width:46px;
	z-index:9;
}
.top-target.fixed{display:none;}
.top-target:hover,
.top-target:focus{color:$white;}

/*
====================================================================================================
Page Shield
====================================================================================================
*/
.page-shield{
	background:rgba(0,0,0,0.7);
	bottom:0;
	display:none;
	left:0;
	position:fixed;
	right:0;
	top:0;
	z-index:1;
}