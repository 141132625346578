/*
======================================================================================================
Fonts
======================================================================================================
*/

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

/*
	font-family:"Montserrat", sans-serif;
	font-family:"Roboto Mono", monospace;
*/

/*
======================================================================================================
Variables
======================================================================================================
*/

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
@media (min-width:$breakpoint-sm) {}
@media (min-width:$breakpoint-md) {}
@media (min-width:$breakpoint-lg) {}

$font-sans-serif:"Montserrat", sans-serif;
$font-monospace:"Roboto Mono", monospace;

$max-width:700px;

$primary:#93A445;
$primary-d2:darken($primary, 30%);
$primary-d1:darken($primary, 15%);
$primary-l1:lighten($primary, 25%);
$primary-l2:lighten($primary, 50%);

$secondary:#58A7AF;
$secondary-d2:darken($secondary, 36%);
$secondary-d1:darken($secondary, 18%);
$secondary-l1:lighten($secondary, 22%);
$secondary-l2:lighten($secondary, 44%);

$tertiary:#FFA400;
$tertiary-d2:darken($tertiary, 36%);
$tertiary-d1:darken($tertiary, 18%);
$tertiary-l1:lighten($tertiary, 22%);
$tertiary-l2:lighten($tertiary, 44%);

$slate:#1D1F29;
$slate-d2:darken($slate, 10%);
$slate-d1:darken($slate, 5%);
$slate-l1:lighten($slate, 35%);
$slate-l2:lighten($slate, 70%);

$alert-high:#D40202;
$alert-high-d2:darken($alert-high, 26%);
$alert-high-d1:darken($alert-high, 13%);
$alert-high-l1:lighten($alert-high, 27%);
$alert-high-l2:lighten($alert-high, 54%);

$alert-medium:#EA7200;
$alert-medium-d2:darken($alert-medium, 30%);
$alert-medium-d1:darken($alert-medium, 15%);
$alert-medium-l1:lighten($alert-medium, 25%);
$alert-medium-l2:lighten($alert-medium, 50%);

$alert-low:#28A63D;
$alert-low-d2:darken($alert-low, 28%);
$alert-low-d1:darken($alert-low, 14%);
$alert-low-l1:lighten($alert-low, 27%);
$alert-low-l2:lighten($alert-low, 54%);

$alert-info:#00ADB3;
$alert-info-d2:darken($alert-info, 24%);
$alert-info-d1:darken($alert-info, 12%);
$alert-info-l1:lighten($alert-info, 30%);
$alert-info-l2:lighten($alert-info, 60%);

$black:#000000;
$gray-1:lighten($black, 8%);
$gray-2:lighten($black, 16%);
$gray-3:lighten($black, 24%);
$gray-4:lighten($black, 32%);
$gray-5:lighten($black, 40%);
$gray-6:lighten($black, 48%);
$gray-7:lighten($black, 56%);
$gray-8:lighten($black, 64%);
$gray-9:lighten($black, 72%);
$gray-10:lighten($black, 80%);
$gray-11:lighten($black, 88%);
$gray-12:lighten($black, 96%);
$white:#ffffff;

$animate:all 0.2s ease-in-out;

/*
======================================================================================================
Defaults
======================================================================================================
*/

html{font-size:62.5%;height:100%;}
body{
	color:$slate;
	font-family:$font-sans-serif;
	font-size:1.6rem;
	line-height:2.8rem;
	height:100%;
	-webkit-font-smoothing:antialiased;
}
.h1, h1{
	font-size:3.6rem;
	font-weight:300;
	line-height:3.8rem;
	margin:0 0 30px;
}
.h2, h2{
	font-size:3.0rem;
	font-weight:300;
	line-height:3.6rem;
	margin:0 0 25px;
}
.h3, h3{
	font-size:2.6rem;
	font-weight:400;
	line-height:3.2rem;
	margin:0 0 25px;
}
.h4, h4{
	font-size:2.4rem;
	font-weight:400;
	line-height:3.0rem;
	margin:0 0 15px;
}
.h5, h5{
	font-size:2.0rem;
	font-weight:700;
	line-height:2.4rem;
	margin:0 0 15px;
}
.h6, h6{
	font-size:1.6rem;
	font-weight:700;
	line-height:2.0rem;
	margin:0 0 15px;
}
code{
	background:$primary-l2;
	color:$black;
	font-family:$font-monospace;
	padding:5px;
}
hr{background:$gray-10;border:none;height:1px;margin:0 0 30px;opacity:1;}
sup{vertical-align:-webkit-baseline-middle;}
img{vertical-align:bottom;}
strong{font-weight:700;}
ul{line-height:2.4rem;margin:0 0 40px 30px;padding:0;}
ul li{margin:0 0 10px;padding:0;}
ul ul{margin:15px 0 15px 45px;}
ol{line-height:2.4rem;margin:0 0 40px 30px;padding:0;}
ol li{margin:0 0 10px;padding:0;}
ol ol{list-style-type:lower-alpha;margin:15px 0 15px 45px;}
p{margin:0 0 35px;}
blockquote{margin:0 0 40px;position:relative;padding-left:40px;}
blockquote .line{
	background:$gray-9;
	height:100%;
	left:0;
	position:absolute;
	top:0;
	width:5px;
}
blockquote .quote{
	display:block;
	font-size:2.0rem;
	font-weight:300;
	line-height:3.0rem;
	margin:0 0 30px;
}
blockquote cite{
	display:flex;
	justify-content:flex-end;
	font-size:1.6rem;
	font-style:normal;
	line-height:2.2rem;
	margin-right:15%;
}
a{color:$primary-d1;text-decoration:none;}
a:hover,
a:focus{color:$black;outline:none;text-decoration:underline;}
a:focus{outline:2px dotted $primary-l1;}
button:focus{outline:2px dotted $primary-l1;}

@media (min-width:$breakpoint-md) {

	blockquote .quote{font-size:3.0rem;line-height:4.0rem;}
	blockquote cite{font-size:1.8rem;line-height:2.2rem;margin-right:20%;}
}
dl{margin:0 0 40px;padding-left:30px;}
dl dt{font-size:1.7rem;}
dl dd{margin:0 0 15px;padding-left:30px;}

/*
======================================================================================================
Tables
======================================================================================================
*/

table{background:$white;border:1px solid $gray-10;margin:0 0 30px;width:100%;}
table thead{background:$gray-12;font-size:1.5rem;line-height:1.8rem;}
table thead th{
	border:1px solid $gray-10;
	font-size:1.8rem;
	font-weight:400;
	padding:10px 20px;
}
table tbody{font-size:1.6rem;line-height:2.2rem;}
table tbody td{
	border-left:1px solid $gray-10;
	border-right:1px solid $gray-10;
	padding:10px 20px;
	vertical-align:top;
}
table tbody tr:nth-child(even){background:$gray-12;}
table tbody tr:nth-child(odd){background:$white;}

/*
======================================================================================================
Forms
======================================================================================================
*/

::-webkit-input-placeholder{color:$gray-3;}
::-moz-placeholder{color:$gray-3;}
:-ms-input-placeholder{color:$gray-3;}
:-moz-placeholder{color:$gray-3;}

form{font-size:1.6rem;line-height:2.4rem;}
form hr{margin:-5px 0 25px;}
form p{margin:0 0 20px;}
form label{display:block;}
form input,
form select,
form textarea{
	background:$white;
	border:1px solid $gray-10;
	border-radius:2px;
	color:$gray-4;
	display:block;
	font-size:1.6rem;
	height:44px;
	line-height:30px;
	outline:none;
	padding:6px 10px;
	transition:$animate;
	width:100%;
}
form textarea{min-height:120px;}
form input:focus,
form select:focus,
form textarea:focus{box-shadow:0 0 3px $primary;}
form label{display:block;margin:0;}
form button{cursor:pointer;}
form .label-text{
	display:block;
	font-size:1.6rem;
	line-height:2.0rem;
	margin:0 0 5px;
}
form .checkboxes label,
form .radios label{padding-left:24px;position:relative;}
form .checkboxes label input,
form .radios label input{
	box-shadow:none;
	display:inline;
	height:auto;
	left:0;
	opacity:0;
	position:absolute;
	top:3px;
	width:auto;
}
form .checkboxes .label-text,
form .radios .label-text{position:relative;}
form .checkboxes .label-text:before,
form .radios .label-text:before{
	color:$gray-5;
	position:absolute;
	font-family:'Font Awesome 6 Free';
	left:-27px;
	padding:0 3px;
	top:0;
}
form .checkboxes label .label-text:before{content:"\f0c8";}
form .radios label .label-text:before{content:"\f111";}
form .checkboxes label input:checked + .label-text:before{content:"\f14a";}
form .radios label input:checked + .label-text:before{content:"\f192";}
form .checkboxes label input:focus + .label-text:before{outline:2px dotted $primary-l2;}
form .radios label input:focus + .label-text:before{outline:2px dotted $primary-l2;}
form .error{color:$alert-high-d1;}
form .error input{
	background:lighten($alert-high, 56%);
	border-color:$alert-high;
}
form .success{color:$alert-low-d1;}
form .success input{
	background:lighten($alert-low, 56%);
	border-color:$alert-low;
}

/*
======================================================================================================
Colors
======================================================================================================
*/

.primary{background-color:$primary !important;}
.primary-text{color:$primary !important;}
.primary-d1{background-color:$primary-d1 !important;}
.primary-d1-text{color:$primary-d1 !important;}
.primary-d2{background-color:$primary-d2 !important;}
.primary-d2-text{color:$primary-d2 !important;}
.primary-l1{background-color:$primary-l1 !important;}
.primary-l1-text{color:$primary-l1 !important;}
.primary-l2{background-color:$primary-l2 !important;}
.primary-l2-text{color:$primary-l2 !important;}

.secondary{background-color:$secondary !important;}
.secondary-text{color:$secondary !important;}
.secondary-d1{background-color:$secondary-d1 !important;}
.secondary-d1-text{color:$secondary-d1 !important;}
.secondary-d2{background-color:$secondary-d2 !important;}
.secondary-d2-text{color:$secondary-d2 !important;}
.secondary-l1{background-color:$secondary-l1 !important;}
.secondary-l1-text{color:$secondary-l1 !important;}
.secondary-l2{background-color:$secondary-l2 !important;}
.secondary-l2-text{color:$secondary-l2 !important;}

.tertiary{background-color:$tertiary !important;}
.tertiary-text{color:$tertiary !important;}
.tertiary-d1{background-color:$tertiary-d1 !important;}
.tertiary-d1-text{color:$tertiary-d1 !important;}
.tertiary-d2{background-color:$tertiary-d2 !important;}
.tertiary-d2-text{color:$tertiary-d2 !important;}
.tertiary-l1{background-color:$tertiary-l1 !important;}
.tertiary-l1-text{color:$tertiary-l1 !important;}
.tertiary-l2{background-color:$tertiary-l2 !important;}
.tertiary-l2-text{color:$tertiary-l2 !important;}

.slate{background-color:$slate !important;}
.slate-text{color:$slate !important;}
.slate-d1{background-color:$slate-d1 !important;}
.slate-d1-text{color:$slate-d1 !important;}
.slate-d2{background-color:$slate-d2 !important;}
.slate-d2-text{color:$slate-d2 !important;}
.slate-l1{background-color:$slate-l1 !important;}
.slate-l1-text{color:$slate-l1 !important;}
.slate-l2{background-color:$slate-l2 !important;}
.slate-l2-text{color:$slate-l2 !important;}

.alert-high{background:$alert-high !important;}
.alert-high-text{color:$alert-high !important;}
.alert-high-d1{background-color:$alert-high-d1 !important;}
.alert-high-d1-text{color:$alert-high-d1 !important;}
.alert-high-d2{background-color:$alert-high-d2 !important;}
.alert-high-d2-text{color:$alert-high-d2 !important;}
.alert-high-l1{background-color:$alert-high-l1 !important;}
.alert-high-l1-text{color:$alert-high-l1 !important;}
.alert-high-l2{background-color:$alert-high-l2 !important;}
.alert-high-l2-text{color:$alert-high-l2 !important;}

.alert-medium{background:$alert-medium !important;}
.alert-medium-text{color:$alert-medium !important;}
.alert-medium-d1{background-color:$alert-medium-d1 !important;}
.alert-medium-d1-text{color:$alert-medium-d1 !important;}
.alert-medium-d2{background-color:$alert-medium-d2 !important;}
.alert-medium-d2-text{color:$alert-medium-d2 !important;}
.alert-medium-l1{background-color:$alert-medium-l1 !important;}
.alert-medium-l1-text{color:$alert-medium-l1 !important;}
.alert-medium-l2{background-color:$alert-medium-l2 !important;}
.alert-medium-l2-text{color:$alert-medium-l2 !important;}

.alert-low{background:$alert-low !important;}
.alert-low-text{color:$alert-low !important;}
.alert-low-d1{background-color:$alert-low-d1 !important;}
.alert-low-d1-text{color:$alert-low-d1 !important;}
.alert-low-d2{background-color:$alert-low-d2 !important;}
.alert-low-d2-text{color:$alert-low-d2 !important;}
.alert-low-l1{background-color:$alert-low-l1 !important;}
.alert-low-l1-text{color:$alert-low-l1 !important;}
.alert-low-l2{background-color:$alert-low-l2 !important;}
.alert-low-l2-text{color:$alert-low-l2 !important;}

.alert-info{background:$alert-info !important;}
.alert-info-text{color:$alert-info !important;}
.alert-info-d1{background-color:$alert-info-d1 !important;}
.alert-info-d1-text{color:$alert-info-d1 !important;}
.alert-info-d2{background-color:$alert-info-d2 !important;}
.alert-info-d2-text{color:$alert-info-d2 !important;}
.alert-info-l1{background-color:$alert-info-l1 !important;}
.alert-info-l1-text{color:$alert-info-l1 !important;}
.alert-info-l2{background-color:$alert-info-l2 !important;}
.alert-info-l2-text{color:$alert-info-l2 !important;}

.black{background-color:$black !important;}
.black-text{color:$black !important;}
.gray-1{background-color:$gray-1 !important;}
.gray-1-text{color:$gray-1 !important;}
.gray-2{background-color:$gray-2 !important;}
.gray-2-text{color:$gray-2 !important;}
.gray-3{background-color:$gray-3 !important;}
.gray-3-text{color:$gray-3 !important;}
.gray-4{background-color:$gray-4 !important;}
.gray-4-text{color:$gray-4 !important;}
.gray-5{background-color:$gray-5 !important;}
.gray-5-text{color:$gray-5 !important;}
.gray-6{background-color:$gray-6 !important;}
.gray-6-text{color:$gray-6 !important;}
.gray-7{background-color:$gray-7 !important;}
.gray-7-text{color:$gray-7 !important;}
.gray-8{background-color:$gray-8 !important;}
.gray-8-text{color:$gray-8 !important;}
.gray-9{background-color:$gray-9 !important;}
.gray-9-text{color:$gray-9 !important;}
.gray-10{background-color:$gray-10 !important;}
.gray-10-text{color:$gray-10 !important;}
.gray-11{background-color:$gray-11 !important;}
.gray-11-text{color:$gray-11 !important;}
.gray-12{background-color:$gray-12 !important;}
.gray-12-text{color:$gray-12 !important;}
.white{background-color:$white !important;}
.white-text{color:$white !important;}

.gradient-primary{
	background: $primary-d2;
	background: -moz-linear-gradient(left,  $primary-d2 0%, $primary 100%);
	background: -webkit-linear-gradient(left,  $primary-d2 0%,$primary 100%);
	background: linear-gradient(to right,  $primary-d2 0%,$primary 100%);
}
.gradient-secondary{
	background: $secondary-d2;
	background: -moz-linear-gradient(left,  $secondary-d2 0%, $secondary 100%);
	background: -webkit-linear-gradient(left,  $secondary-d2 0%,$secondary 100%);
	background: linear-gradient(to right,  $secondary-d2 0%,$secondary 100%);
}

/*
======================================================================================================
Font Styles
======================================================================================================
*/

.font-size-xl{font-size:2.2rem;line-height:3.3rem;}
.font-size-lg{font-size:1.9rem;line-height:3.0rem;}
.font-size-md{font-size:1.6rem;line-height:2.8rem;}
.font-size-sm{font-size:1.4rem;line-height:2.4rem;}
.font-size-xs{font-size:1.3rem;line-height:1.9rem;}

.text-caption{
	display:block;
	font-size:1.4rem;
	line-height:2.0rem;
	margin:10px 0;
}

.text-cta{
	display:inline-block;
	font-weight:700;
	padding-right:14px;
	position:relative;
	text-transform:uppercase;
}
.text-cta i{position:absolute;right:0;top:5px;}

.text-external{
	display:inline-block;
	margin-right:2px;
	padding-right:22px;
	position:relative;
}
.text-external:after{
	content:"\f08e";
	font-family:'Font Awesome 6 Free';
	font-weight:900;
	position:absolute;
	right:0;
	top:0;
}

.text-label{
	color:$slate;
	font-size:1.3rem;
	font-weight:700;
	line-height:2.2rem;
	text-transform:uppercase;
}

.text-meta{
	color:$slate;
	font-size:1.3rem;
	line-height:2.2rem;
}

/*
======================================================================================================
Buttons
======================================================================================================
*/
.button{
	background:$primary;
	border:none;
	color:$white;
	cursor:pointer;
	display:inline-block;
	font-size:1.6rem;
	font-weight:600;
	letter-spacing:0.06rem;
	line-height:2.6rem;
	min-width:166px;
	outline:none;
	padding:9px 20px;
	text-align:center;
	transition:$animate;
}
.button:hover,
.button:focus{
	background:$primary-d1;
	color:$white;
	outline:none;
	text-decoration:none;
}

.button-sm{min-width:100px;padding:2px 20px}

.button.secondary:hover,
.button.secondary:focus{background:$secondary-d1 !important;}
.button.tertiary{color:$black;}
.button.tertiary:hover,
.button.tertiary:focus{color:$white;background:$primary-d1 !important;}
.button.slate:hover,
.button.slate:focus{background:$primary-d1 !important;}

/*
======================================================================================================
Container
======================================================================================================
*/
.container{max-width:100%;padding-left:20px;padding-right:20px;}
.container-fluid{padding-left:20px;padding-right:20px;}

@media (min-width:$breakpoint-xl) {
	.container{max-width:1140px;}
}

/*
======================================================================================================
Utilities
======================================================================================================
*/
.border{border:1px solid $gray-11 !important;}
.border-none{border:none !important;}
.clickable{cursor:pointer;}
.filter-grayscale{filter:grayscale(1);}
.hr-lg{height:4px !important;}
.hr-md{height:2px !important;}
.margin-0{margin:0 !important;}
.margin-5{margin-bottom:5px !important;}
.margin-10{margin-bottom:10px !important;}
.margin-15{margin-bottom:15px !important;}
.margin-20{margin-bottom:20px !important;}
.margin-30{margin-bottom:30px !important;}
.margin-40{margin-bottom:40px !important;}
.margin-50{margin-bottom:50px !important;}
.margin-60{margin-bottom:60px !important;}
.margin-auto{margin-left:auto !important;margin-right:auto !important;}
.margin-pull-10{margin-bottom:-10px !important;}
.margin-pull-20{margin-bottom:-20px !important;}
.margin-pull-30{margin-bottom:-30px !important;}
.margin-pull-40{margin-bottom:-40px !important;}
.margin-pull-50{margin-bottom:-50px !important;}
.margin-pull-60{margin-bottom:-60px !important;}
.nowrap{white-space:nowrap;}
.opacity-10{opacity:0.1;}
.opacity-20{opacity:0.2;}
.opacity-30{opacity:0.3;}
.opacity-40{opacity:0.4;}
.opacity-50{opacity:0.5;}
.opacity-60{opacity:0.6;}
.opacity-70{opacity:0.7;}
.opacity-80{opacity:0.8;}
.opacity-90{opacity:0.9;}
.overflow-auto{overflow:auto !important;}
.overflow-hidden{overflow:hidden !important;}
.overflow-visible{overflow:visible !important;}
.padding-0{padding:0 !important;}
.padding-10{padding:10px !important;}
.padding-20{padding:20px !important;}
.padding-30{padding:30px !important;}
.padding-40{padding:40px !important;}
.padding-50{padding:50px !important;}
.padding-60{padding:60px !important;}
.pipe{margin:0 5px;}
.row{margin-left:-15px;margin-right:-15px;}
.row > div{padding-left:15px;padding-right:15px;}
.row-gutter-0{margin-left:0;margin-right:0;}
.row-gutter-0 > div{padding-left:0;padding-right:0;}
.row-gutter-10{margin-left:-5px;margin-right:-5px;}
.row-gutter-10 > div{padding-left:5px;padding-right:5px;}
.row-gutter-20{margin-left:-10px;margin-right:-10px;}
.row-gutter-20 > div{padding-left:10px;padding-right:10px;}
.row-gutter-30{margin-left:-15px;margin-right:-15px;}
.row-gutter-30 > div{padding-left:15px;padding-right:15px;}
.row-gutter-40{margin-left:-20px;margin-right:-20px;}
.row-gutter-40 > div{padding-left:20px;padding-right:20px;}
.row-gutter-50{margin-left:-25px;margin-right:-25px;}
.row-gutter-50 > div{padding-left:25px;padding-right:25px;}
.row-gutter-60{margin-left:-30px;margin-right:-30px;}
.row-gutter-60 > div{padding-left:30px;padding-right:30px;}
.row-offset-wrap{margin:-2px;overflow:hidden;padding:2px;}
.spacer-5{clear:both;height:5px;}
.spacer-10{clear:both;height:10px;}
.spacer-20{clear:both;height:20px;}
.spacer-30{clear:both;height:30px;}
.spacer-40{clear:both;height:40px;}
.spacer-50{clear:both;height:50px;}
.spacer-60{clear:both;height:60px;}
.spacer-70{clear:both;height:70px;}
.spacer-80{clear:both;height:80px;}
.text-align-center{text-align:center;}
.text-align-justify{text-align:justify;}
.text-align-left{text-align:left;}
.text-align-right{text-align:right;}
.text-indent{padding-left:30px;}
.text-wrap{word-wrap:break-word;}
.text-uppercase{text-transform:uppercase;}
.text-truncate{
	display:block;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	width:100%;
}
.visually-hidden{
	clip:rect(1px 1px 1px 1px);
	clip:rect(1px, 1px, 1px, 1px);
	height:1px;
	overflow:hidden;
	position:absolute !important;
	white-space:nowrap;
	width:1px;
}