/*
======================================================================================================
Tray Styles
======================================================================================================
*/
.tray{padding:40px 0 10px;position:relative;}
.tray .container{position:relative;z-index:1;}

.tray-alert{
	background:$black;
	bottom:0;
	color:$white;
	font-size:1.6rem;
	line-height:2.2rem;
	padding:24px 0;
	position:sticky;
	left:0;
	width:100%;
	z-index:2;
}
.tray-alert p{margin:0;}
.tray-alert .container{position:relative;}
.tray-alert .content{display:flex;padding-right:40px;}
.tray-alert .content .icon{font-size:3.0rem;margin:2px 15px 0 0;}
.tray-alert .close-alert{
	background:transparent;
	border:none;
	color:$white;
	display:inline-block;
	padding:5px 10px;
	position:absolute;
	right:20px;
	text-decoration:none;
	top:-3px;
}

.tray-banner{padding:20px 0 30px;}
.tray-banner .h1, .tray-banner h1{margin:0;}
@media (min-width:$breakpoint-md) {

	.tray-banner{padding:22px 0 32px;}
	.tray-banner .h1, .tray-banner h1{font-size:4.0rem;line-height:4.0rem;}

}

.tray-vps .content{margin:0 auto;max-width:800px;}
.tray-vps .form-search{background:transparent;margin:0 auto 30px;max-width:550px;padding:0;}
.tray-vps .form-search input::-moz-placeholder{opacity:1;}
.tray-vps .form-search input{
	background:rgba(255,255,255,0.7);
	border-radius:5px;
	height:52px;
}
.tray-vps .form-search input:focus{background:rgba(255,255,255,1.0);}
.tray-vps .form-search button{color:$primary-d1;height:52px;}

@media (min-width:$breakpoint-md) {

	.tray-vps h1, .tray-vps .h1{font-size:4.0rem;line-height:4.0rem;}

}