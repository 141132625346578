/*
======================================================================================================
Fonts
======================================================================================================
*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");
/*
	font-family:"Montserrat", sans-serif;
	font-family:"Roboto Mono", monospace;
*/
/*
======================================================================================================
Variables
======================================================================================================
*/
/*
======================================================================================================
Defaults
======================================================================================================
*/
html {
  font-size: 62.5%;
  height: 100%;
}

body {
  color: #1D1F29;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  line-height: 2.8rem;
  height: 100%;
  -webkit-font-smoothing: antialiased;
}

.h1, h1 {
  font-size: 3.6rem;
  font-weight: 300;
  line-height: 3.8rem;
  margin: 0 0 30px;
}

.h2, h2 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 3.6rem;
  margin: 0 0 25px;
}

.h3, h3 {
  font-size: 2.6rem;
  font-weight: 400;
  line-height: 3.2rem;
  margin: 0 0 25px;
}

.h4, h4 {
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 3rem;
  margin: 0 0 15px;
}

.h5, h5 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.4rem;
  margin: 0 0 15px;
}

.h6, h6 {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2rem;
  margin: 0 0 15px;
}

code {
  background: #f7f8f0;
  color: #000000;
  font-family: "Roboto Mono", monospace;
  padding: 5px;
}

hr {
  background: #cccccc;
  border: none;
  height: 1px;
  margin: 0 0 30px;
  opacity: 1;
}

sup {
  vertical-align: -webkit-baseline-middle;
}

img {
  vertical-align: bottom;
}

strong {
  font-weight: 700;
}

ul {
  line-height: 2.4rem;
  margin: 0 0 40px 30px;
  padding: 0;
}

ul li {
  margin: 0 0 10px;
  padding: 0;
}

ul ul {
  margin: 15px 0 15px 45px;
}

ol {
  line-height: 2.4rem;
  margin: 0 0 40px 30px;
  padding: 0;
}

ol li {
  margin: 0 0 10px;
  padding: 0;
}

ol ol {
  list-style-type: lower-alpha;
  margin: 15px 0 15px 45px;
}

p {
  margin: 0 0 35px;
}

blockquote {
  margin: 0 0 40px;
  position: relative;
  padding-left: 40px;
}

blockquote .line {
  background: #b8b8b8;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 5px;
}

blockquote .quote {
  display: block;
  font-size: 2rem;
  font-weight: 300;
  line-height: 3rem;
  margin: 0 0 30px;
}

blockquote cite {
  display: flex;
  justify-content: flex-end;
  font-size: 1.6rem;
  font-style: normal;
  line-height: 2.2rem;
  margin-right: 15%;
}

a {
  color: #636e2e;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #000000;
  outline: none;
  text-decoration: underline;
}

a:focus {
  outline: 2px dotted #c8d396;
}

button:focus {
  outline: 2px dotted #c8d396;
}

@media (min-width: 768px) {
  blockquote .quote {
    font-size: 3rem;
    line-height: 4rem;
  }
  blockquote cite {
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-right: 20%;
  }
}
dl {
  margin: 0 0 40px;
  padding-left: 30px;
}

dl dt {
  font-size: 1.7rem;
}

dl dd {
  margin: 0 0 15px;
  padding-left: 30px;
}

/*
======================================================================================================
Tables
======================================================================================================
*/
table {
  background: #ffffff;
  border: 1px solid #cccccc;
  margin: 0 0 30px;
  width: 100%;
}

table thead {
  background: whitesmoke;
  font-size: 1.5rem;
  line-height: 1.8rem;
}

table thead th {
  border: 1px solid #cccccc;
  font-size: 1.8rem;
  font-weight: 400;
  padding: 10px 20px;
}

table tbody {
  font-size: 1.6rem;
  line-height: 2.2rem;
}

table tbody td {
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  padding: 10px 20px;
  vertical-align: top;
}

table tbody tr:nth-child(even) {
  background: whitesmoke;
}

table tbody tr:nth-child(odd) {
  background: #ffffff;
}

/*
======================================================================================================
Forms
======================================================================================================
*/
::-webkit-input-placeholder {
  color: #3d3d3d;
}

::-moz-placeholder {
  color: #3d3d3d;
}

:-ms-input-placeholder {
  color: #3d3d3d;
}

:-moz-placeholder {
  color: #3d3d3d;
}

form {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

form hr {
  margin: -5px 0 25px;
}

form p {
  margin: 0 0 20px;
}

form label {
  display: block;
}

form input,
form select,
form textarea {
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 2px;
  color: #525252;
  display: block;
  font-size: 1.6rem;
  height: 44px;
  line-height: 30px;
  outline: none;
  padding: 6px 10px;
  transition: all 0.2s ease-in-out;
  width: 100%;
}

form textarea {
  min-height: 120px;
}

form input:focus,
form select:focus,
form textarea:focus {
  box-shadow: 0 0 3px #93A445;
}

form label {
  display: block;
  margin: 0;
}

form button {
  cursor: pointer;
}

form .label-text {
  display: block;
  font-size: 1.6rem;
  line-height: 2rem;
  margin: 0 0 5px;
}

form .checkboxes label,
form .radios label {
  padding-left: 24px;
  position: relative;
}

form .checkboxes label input,
form .radios label input {
  box-shadow: none;
  display: inline;
  height: auto;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 3px;
  width: auto;
}

form .checkboxes .label-text,
form .radios .label-text {
  position: relative;
}

form .checkboxes .label-text:before,
form .radios .label-text:before {
  color: #666666;
  position: absolute;
  font-family: "Font Awesome 6 Free";
  left: -27px;
  padding: 0 3px;
  top: 0;
}

form .checkboxes label .label-text:before {
  content: "\f0c8";
}

form .radios label .label-text:before {
  content: "\f111";
}

form .checkboxes label input:checked + .label-text:before {
  content: "\f14a";
}

form .radios label input:checked + .label-text:before {
  content: "\f192";
}

form .checkboxes label input:focus + .label-text:before {
  outline: 2px dotted #f7f8f0;
}

form .radios label input:focus + .label-text:before {
  outline: 2px dotted #f7f8f0;
}

form .error {
  color: #920101;
}

form .error input {
  background: #fff5f5;
  border-color: #D40202;
}

form .success {
  color: #1a6c28;
}

form .success input {
  background: #f0fbf2;
  border-color: #28A63D;
}

/*
======================================================================================================
Colors
======================================================================================================
*/
.primary {
  background-color: #93A445 !important;
}

.primary-text {
  color: #93A445 !important;
}

.primary-d1 {
  background-color: #636e2e !important;
}

.primary-d1-text {
  color: #636e2e !important;
}

.primary-d2 {
  background-color: #323818 !important;
}

.primary-d2-text {
  color: #323818 !important;
}

.primary-l1 {
  background-color: #c8d396 !important;
}

.primary-l1-text {
  color: #c8d396 !important;
}

.primary-l2 {
  background-color: #f7f8f0 !important;
}

.primary-l2-text {
  color: #f7f8f0 !important;
}

.secondary {
  background-color: #58A7AF !important;
}

.secondary-text {
  color: #58A7AF !important;
}

.secondary-d1 {
  background-color: #376e74 !important;
}

.secondary-d1-text {
  color: #376e74 !important;
}

.secondary-d2 {
  background-color: #1a3336 !important;
}

.secondary-d2-text {
  color: #1a3336 !important;
}

.secondary-l1 {
  background-color: #a4cfd3 !important;
}

.secondary-l1-text {
  color: #a4cfd3 !important;
}

.secondary-l2 {
  background-color: #f0f7f8 !important;
}

.secondary-l2-text {
  color: #f0f7f8 !important;
}

.tertiary {
  background-color: #FFA400 !important;
}

.tertiary-text {
  color: #FFA400 !important;
}

.tertiary-d1 {
  background-color: #a36900 !important;
}

.tertiary-d1-text {
  color: #a36900 !important;
}

.tertiary-d2 {
  background-color: #472e00 !important;
}

.tertiary-d2-text {
  color: #472e00 !important;
}

.tertiary-l1 {
  background-color: #ffcc70 !important;
}

.tertiary-l1-text {
  color: #ffcc70 !important;
}

.tertiary-l2 {
  background-color: #fff4e0 !important;
}

.tertiary-l2-text {
  color: #fff4e0 !important;
}

.slate {
  background-color: #1D1F29 !important;
}

.slate-text {
  color: #1D1F29 !important;
}

.slate-d1 {
  background-color: #12141a !important;
}

.slate-d1-text {
  color: #12141a !important;
}

.slate-d2 {
  background-color: #08080b !important;
}

.slate-d2-text {
  color: #08080b !important;
}

.slate-l1 {
  background-color: #676e92 !important;
}

.slate-l1-text {
  color: #676e92 !important;
}

.slate-l2 {
  background-color: #ced1dd !important;
}

.slate-l2-text {
  color: #ced1dd !important;
}

.alert-high {
  background: #D40202 !important;
}

.alert-high-text {
  color: #D40202 !important;
}

.alert-high-d1 {
  background-color: #920101 !important;
}

.alert-high-d1-text {
  color: #920101 !important;
}

.alert-high-d2 {
  background-color: #510101 !important;
}

.alert-high-d2-text {
  color: #510101 !important;
}

.alert-high-l1 {
  background-color: #fe6262 !important;
}

.alert-high-l1-text {
  color: #fe6262 !important;
}

.alert-high-l2 {
  background-color: #ffebeb !important;
}

.alert-high-l2-text {
  color: #ffebeb !important;
}

.alert-medium {
  background: #EA7200 !important;
}

.alert-medium-text {
  color: #EA7200 !important;
}

.alert-medium-d1 {
  background-color: #9e4d00 !important;
}

.alert-medium-d1-text {
  color: #9e4d00 !important;
}

.alert-medium-d2 {
  background-color: #512700 !important;
}

.alert-medium-d2-text {
  color: #512700 !important;
}

.alert-medium-l1 {
  background-color: #ffb36b !important;
}

.alert-medium-l1-text {
  color: #ffb36b !important;
}

.alert-medium-l2 {
  background-color: #fff4ea !important;
}

.alert-medium-l2-text {
  color: #fff4ea !important;
}

.alert-low {
  background: #28A63D !important;
}

.alert-low-text {
  color: #28A63D !important;
}

.alert-low-d1 {
  background-color: #1a6c28 !important;
}

.alert-low-d1-text {
  color: #1a6c28 !important;
}

.alert-low-d2 {
  background-color: #0c3313 !important;
}

.alert-low-d2-text {
  color: #0c3313 !important;
}

.alert-low-l1 {
  background-color: #79df8a !important;
}

.alert-low-l1-text {
  color: #79df8a !important;
}

.alert-low-l2 {
  background-color: #e8f9eb !important;
}

.alert-low-l2-text {
  color: #e8f9eb !important;
}

.alert-info {
  background: #00ADB3 !important;
}

.alert-info-text {
  color: #00ADB3 !important;
}

.alert-info-d1 {
  background-color: #007276 !important;
}

.alert-info-d1-text {
  color: #007276 !important;
}

.alert-info-d2 {
  background-color: #003739 !important;
}

.alert-info-d2-text {
  color: #003739 !important;
}

.alert-info-l1 {
  background-color: #4df9ff !important;
}

.alert-info-l1-text {
  color: #4df9ff !important;
}

.alert-info-l2 {
  background-color: #e6feff !important;
}

.alert-info-l2-text {
  color: #e6feff !important;
}

.black {
  background-color: #000000 !important;
}

.black-text {
  color: #000000 !important;
}

.gray-1 {
  background-color: #141414 !important;
}

.gray-1-text {
  color: #141414 !important;
}

.gray-2 {
  background-color: #292929 !important;
}

.gray-2-text {
  color: #292929 !important;
}

.gray-3 {
  background-color: #3d3d3d !important;
}

.gray-3-text {
  color: #3d3d3d !important;
}

.gray-4 {
  background-color: #525252 !important;
}

.gray-4-text {
  color: #525252 !important;
}

.gray-5 {
  background-color: #666666 !important;
}

.gray-5-text {
  color: #666666 !important;
}

.gray-6 {
  background-color: #7a7a7a !important;
}

.gray-6-text {
  color: #7a7a7a !important;
}

.gray-7 {
  background-color: #8f8f8f !important;
}

.gray-7-text {
  color: #8f8f8f !important;
}

.gray-8 {
  background-color: #a3a3a3 !important;
}

.gray-8-text {
  color: #a3a3a3 !important;
}

.gray-9 {
  background-color: #b8b8b8 !important;
}

.gray-9-text {
  color: #b8b8b8 !important;
}

.gray-10 {
  background-color: #cccccc !important;
}

.gray-10-text {
  color: #cccccc !important;
}

.gray-11 {
  background-color: #e0e0e0 !important;
}

.gray-11-text {
  color: #e0e0e0 !important;
}

.gray-12 {
  background-color: whitesmoke !important;
}

.gray-12-text {
  color: whitesmoke !important;
}

.white {
  background-color: #ffffff !important;
}

.white-text {
  color: #ffffff !important;
}

.gradient-primary {
  background: #323818;
  background: -moz-linear-gradient(left, #323818 0%, #93A445 100%);
  background: -webkit-linear-gradient(left, #323818 0%, #93A445 100%);
  background: linear-gradient(to right, #323818 0%, #93A445 100%);
}

.gradient-secondary {
  background: #1a3336;
  background: -moz-linear-gradient(left, #1a3336 0%, #58A7AF 100%);
  background: -webkit-linear-gradient(left, #1a3336 0%, #58A7AF 100%);
  background: linear-gradient(to right, #1a3336 0%, #58A7AF 100%);
}

/*
======================================================================================================
Font Styles
======================================================================================================
*/
.font-size-xl {
  font-size: 2.2rem;
  line-height: 3.3rem;
}

.font-size-lg {
  font-size: 1.9rem;
  line-height: 3rem;
}

.font-size-md {
  font-size: 1.6rem;
  line-height: 2.8rem;
}

.font-size-sm {
  font-size: 1.4rem;
  line-height: 2.4rem;
}

.font-size-xs {
  font-size: 1.3rem;
  line-height: 1.9rem;
}

.text-caption {
  display: block;
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 10px 0;
}

.text-cta {
  display: inline-block;
  font-weight: 700;
  padding-right: 14px;
  position: relative;
  text-transform: uppercase;
}

.text-cta i {
  position: absolute;
  right: 0;
  top: 5px;
}

.text-external {
  display: inline-block;
  margin-right: 2px;
  padding-right: 22px;
  position: relative;
}

.text-external:after {
  content: "\f08e";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  position: absolute;
  right: 0;
  top: 0;
}

.text-label {
  color: #1D1F29;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 2.2rem;
  text-transform: uppercase;
}

.text-meta {
  color: #1D1F29;
  font-size: 1.3rem;
  line-height: 2.2rem;
}

/*
======================================================================================================
Buttons
======================================================================================================
*/
.button {
  background: #93A445;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.06rem;
  line-height: 2.6rem;
  min-width: 166px;
  outline: none;
  padding: 9px 20px;
  text-align: center;
  transition: all 0.2s ease-in-out;
}

.button:hover,
.button:focus {
  background: #636e2e;
  color: #ffffff;
  outline: none;
  text-decoration: none;
}

.button-sm {
  min-width: 100px;
  padding: 2px 20px;
}

.button.secondary:hover,
.button.secondary:focus {
  background: #376e74 !important;
}

.button.tertiary {
  color: #000000;
}

.button.tertiary:hover,
.button.tertiary:focus {
  color: #ffffff;
  background: #636e2e !important;
}

.button.slate:hover,
.button.slate:focus {
  background: #636e2e !important;
}

/*
======================================================================================================
Container
======================================================================================================
*/
.container {
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.container-fluid {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
/*
======================================================================================================
Utilities
======================================================================================================
*/
.border {
  border: 1px solid #e0e0e0 !important;
}

.border-none {
  border: none !important;
}

.clickable {
  cursor: pointer;
}

.filter-grayscale {
  filter: grayscale(1);
}

.hr-lg {
  height: 4px !important;
}

.hr-md {
  height: 2px !important;
}

.margin-0 {
  margin: 0 !important;
}

.margin-5 {
  margin-bottom: 5px !important;
}

.margin-10 {
  margin-bottom: 10px !important;
}

.margin-15 {
  margin-bottom: 15px !important;
}

.margin-20 {
  margin-bottom: 20px !important;
}

.margin-30 {
  margin-bottom: 30px !important;
}

.margin-40 {
  margin-bottom: 40px !important;
}

.margin-50 {
  margin-bottom: 50px !important;
}

.margin-60 {
  margin-bottom: 60px !important;
}

.margin-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.margin-pull-10 {
  margin-bottom: -10px !important;
}

.margin-pull-20 {
  margin-bottom: -20px !important;
}

.margin-pull-30 {
  margin-bottom: -30px !important;
}

.margin-pull-40 {
  margin-bottom: -40px !important;
}

.margin-pull-50 {
  margin-bottom: -50px !important;
}

.margin-pull-60 {
  margin-bottom: -60px !important;
}

.nowrap {
  white-space: nowrap;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-90 {
  opacity: 0.9;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.padding-0 {
  padding: 0 !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-20 {
  padding: 20px !important;
}

.padding-30 {
  padding: 30px !important;
}

.padding-40 {
  padding: 40px !important;
}

.padding-50 {
  padding: 50px !important;
}

.padding-60 {
  padding: 60px !important;
}

.pipe {
  margin: 0 5px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.row > div {
  padding-left: 15px;
  padding-right: 15px;
}

.row-gutter-0 {
  margin-left: 0;
  margin-right: 0;
}

.row-gutter-0 > div {
  padding-left: 0;
  padding-right: 0;
}

.row-gutter-10 {
  margin-left: -5px;
  margin-right: -5px;
}

.row-gutter-10 > div {
  padding-left: 5px;
  padding-right: 5px;
}

.row-gutter-20 {
  margin-left: -10px;
  margin-right: -10px;
}

.row-gutter-20 > div {
  padding-left: 10px;
  padding-right: 10px;
}

.row-gutter-30 {
  margin-left: -15px;
  margin-right: -15px;
}

.row-gutter-30 > div {
  padding-left: 15px;
  padding-right: 15px;
}

.row-gutter-40 {
  margin-left: -20px;
  margin-right: -20px;
}

.row-gutter-40 > div {
  padding-left: 20px;
  padding-right: 20px;
}

.row-gutter-50 {
  margin-left: -25px;
  margin-right: -25px;
}

.row-gutter-50 > div {
  padding-left: 25px;
  padding-right: 25px;
}

.row-gutter-60 {
  margin-left: -30px;
  margin-right: -30px;
}

.row-gutter-60 > div {
  padding-left: 30px;
  padding-right: 30px;
}

.row-offset-wrap {
  margin: -2px;
  overflow: hidden;
  padding: 2px;
}

.spacer-5 {
  clear: both;
  height: 5px;
}

.spacer-10 {
  clear: both;
  height: 10px;
}

.spacer-20 {
  clear: both;
  height: 20px;
}

.spacer-30 {
  clear: both;
  height: 30px;
}

.spacer-40 {
  clear: both;
  height: 40px;
}

.spacer-50 {
  clear: both;
  height: 50px;
}

.spacer-60 {
  clear: both;
  height: 60px;
}

.spacer-70 {
  clear: both;
  height: 70px;
}

.spacer-80 {
  clear: both;
  height: 80px;
}

.text-align-center {
  text-align: center;
}

.text-align-justify {
  text-align: justify;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-indent {
  padding-left: 30px;
}

.text-wrap {
  word-wrap: break-word;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-truncate {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.visually-hidden {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

/*
====================================================================================================
#Page
====================================================================================================
*/
#page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

#page main {
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
  transition: all 0.2s ease-in-out;
}

@media (min-width: 768px) {
  #page .side-stick {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
/*
====================================================================================================
Docs Layout
====================================================================================================
*/
/* Docs Layout */
#page.layout-docs {
  padding: 0;
}

#page.layout-docs header {
  border-bottom: none;
  height: auto;
  position: sticky;
}

#page.layout-docs header:before {
  display: none;
}

#page.layout-docs footer .footer-content {
  transition: all 0.2s ease-in-out;
}

#page.layout-docs .tray-alert .alert-content {
  transition: all 0.2s ease-in-out;
}

@media (min-width: 1200px) {
  #page.layout-docs main {
    padding-left: 350px;
  }
  #page.layout-docs footer .footer-content {
    padding-left: 350px;
  }
  #page.layout-docs .tray-alert .alert-content {
    padding-left: 350px;
  }
  #page.layout-docs.toc-closed .toc {
    max-width: 56px;
  }
  #page.layout-docs.toc-closed .toc .toc-content {
    opacity: 0;
  }
  #page.layout-docs.toc-closed .toc .toc-tabs {
    left: auto;
    right: 0;
  }
  #page.layout-docs.toc-closed main {
    padding-left: 20px;
  }
  #page.layout-docs.toc-closed footer .footer-content {
    padding-left: 20px;
  }
  #page.layout-docs.toc-closed .tray-alert .alert-content {
    padding-left: 20px;
  }
}
/*
====================================================================================================
Header
====================================================================================================
*/
header.opened-search .header-content .dropdowns > ul > li > .dropdown,
header.opened-search .header-content .dropdowns > ul > li > ul {
  display: none !important;
}

header {
  background: #ffffff;
  border-bottom: 5px solid #58A7AF;
  left: 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
}

header:before {
  background: #1a3336;
  background: -moz-linear-gradient(left, #1a3336 0%, #58A7AF 100%);
  background: -webkit-linear-gradient(left, #1a3336 0%, #58A7AF 100%);
  background: linear-gradient(to right, #1a3336 0%, #58A7AF 100%);
  bottom: -5px;
  content: " ";
  height: 5px;
  left: 0;
  position: absolute;
  width: 100%;
}

header .alert-content {
  background: #000000;
  bottom: 0;
  color: #ffffff;
  font-size: 1.6rem;
  line-height: 2.2rem;
  padding: 24px 0;
  position: sticky;
  left: 0;
  width: 100%;
  z-index: 2;
}

header .alert-content p {
  margin: 0;
}

header .alert-content .container {
  position: relative;
}

header .alert-content .content {
  display: flex;
  padding-right: 40px;
}

header .alert-content .content .icon {
  font-size: 3rem;
  margin: 2px 15px 0 0;
}

header .alert-content .close-alert {
  color: #ffffff;
  display: inline-block;
  padding: 5px 10px;
  position: absolute;
  right: 20px;
  text-decoration: none;
  top: -3px;
}

header .header-content {
  padding: 10px 0;
  position: relative;
}

header .header-content .container {
  position: relative;
}

header .header-content .container-fluid {
  position: relative;
}

header .header-content .skip-to-content {
  background: #93A445;
  border: 2px solid #93A445;
  color: #ffffff;
  display: inline-block;
  font-size: 1.6rem;
  left: -2000%;
  line-height: 20px;
  margin-top: -20px;
  min-width: 160px;
  padding: 8px 20px;
  position: absolute;
  text-decoration: none;
  text-align: center;
  top: 50%;
  z-index: 2;
}

header .header-content .skip-to-content:focus {
  left: auto;
  right: 15px;
}

header .header-content .logo {
  display: inline-block;
}

header .header-content .logo img {
  height: 60px;
}

header .header-content .dropdowns {
  display: none;
  margin-top: -24px;
  position: absolute;
  right: 130px;
  top: 50%;
}

header .header-content .dropdowns > ul {
  display: flex;
  font-size: 1.5rem;
  line-height: 2rem;
  list-style: none;
  margin: 0;
  z-index: 1;
}

header .header-content .dropdowns > ul > li {
  margin: 0;
  padding: 0 2px;
  position: relative;
}

header .header-content .dropdowns > ul > li > a {
  color: #000000;
  display: block;
  padding: 14px 15px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

header .header-content .dropdowns > ul > li:hover > a,
header .header-content .dropdowns > ul > li:focus > a,
header .header-content .dropdowns > ul > li.focused > a {
  color: #636e2e;
  text-decoration: underline;
}

header .header-content .dropdowns > ul > li:hover > ul,
header .header-content .dropdowns > ul > li:focus > ul,
header .header-content .dropdowns > ul > li.focused > ul {
  height: auto;
  opacity: 1;
  width: 220px;
}

header .header-content .dropdowns > ul > li > ul {
  background: #ffffff;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  height: 0;
  list-style: none;
  left: 50%;
  margin: 0;
  margin-left: -110px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  text-align: center;
  top: 100%;
  width: 0;
}

header .header-content .dropdowns > ul > li > ul > li:first-child {
  margin-top: 15px;
}

header .header-content .dropdowns > ul > li > ul > li:last-child {
  margin-bottom: 15px;
}

header .header-content .dropdowns > ul > li > ul > li {
  margin: 0;
}

header .header-content .dropdowns > ul > li > ul > li > a {
  color: #000000;
  display: block;
  padding: 6px 20px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

header .header-content .dropdowns > ul > li > ul > li > a:hover,
header .header-content .dropdowns > ul > li > ul > li > a:focus {
  background: whitesmoke;
  color: black;
}

header .header-content .links {
  font-size: 2.4rem;
  line-height: 48px;
  margin-top: -24px;
  position: absolute;
  right: 10px;
  top: 50%;
}

header .header-content .links > a {
  color: #636e2e;
  display: inline-block;
  height: 48px;
  line-height: 48px;
  margin: 0 0 0 10px;
  padding: 0;
  text-align: center;
  text-decoration: none;
  width: 48px;
}

header .header-content .links > a:hover,
header .header-content .links > a:focus {
  color: #000000;
}

header .header-content .links .open-menu i:last-child {
  display: none;
}

header .header-content .links .open-menu.opened i:first-child {
  display: none;
}

header .header-content .links .open-menu.opened i:last-child {
  display: inline;
}

header .header-content .links .link-login {
  display: none;
}

header .header-content .links .open-search {
  display: none;
}

header .header-content .links .user-menu {
  display: none;
  margin: 0;
  position: relative;
  z-index: 1;
}

header .header-content .links .user {
  color: #636e2e;
  display: inline-block;
  height: 48px;
  line-height: 48px;
  margin: 0 0 0 10px;
  padding: 0;
  text-decoration: none;
  text-align: center;
  width: 48px;
}

header .header-content .links .user:hover,
header .header-content .links .user:focus {
  color: #000000;
}

header .header-content .links .user i:last-child {
  font-size: 1.8rem;
}

header .header-content .links .user-links {
  background: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: none;
  font-size: 1.5rem;
  line-height: 1.8rem;
  position: absolute;
  right: 0;
  top: 54px;
  width: 275px;
}

header .header-content .links .user-links span {
  background: whitesmoke;
  color: #000000;
  display: block;
  font-size: 1.8rem;
  margin: 0;
  padding: 10px 20px;
}

header .header-content .links .user-links span i {
  margin-right: 5px;
}

header .header-content .links .user-links a {
  border-top: 1px solid #cccccc;
  color: #323818;
  display: block;
  margin: 0;
  padding: 10px 20px;
}

header .header-content .links .user-links a:hover,
header .header-content .links .user-links a:focus {
  background: whitesmoke;
  color: #000000;
  text-decoration: none;
}

@media (min-width: 992px) {
  header .header-content {
    text-align: left;
  }
  header .header-content .dropdowns {
    display: flex;
  }
  header .header-content .links {
    font-size: 2rem;
  }
  header .header-content .links .link-login {
    display: inline-block;
  }
  header .header-content .links .open-search {
    display: inline-block;
  }
  header .header-content .links .open-menu {
    display: none;
  }
  header .header-content .links .user-menu {
    display: inline-block;
  }
}
@media (min-width: 1200px) {
  header .header-content .open-toc {
    display: none;
  }
  header .header-content .dropdowns > ul > li {
    padding: 0 10px;
  }
}
header .search-content {
  background: #ffffff;
  border-bottom: 5px solid #1D1F29;
  border-top: 1px solid #cccccc;
  display: none;
  left: 0;
  padding: 10px 0;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;
}

header .search-content .search {
  margin: 0 auto 10px;
  max-width: 720px;
  padding-right: 60px;
  position: relative;
}

header .search-content .search input {
  border: none;
  border-bottom: 2px solid #e0e0e0;
  height: 52px;
  font-size: 1.8rem;
  padding-left: 0;
  padding-right: 55px;
}

header .search-content .search input:focus {
  box-shadow: none;
}

header .search-content .search button {
  background: transparent;
  border: none;
  color: #93A445;
  font-size: 3rem;
  height: 52px;
  line-height: 52px;
  position: absolute;
  right: 60px;
  top: 0;
  width: 52px;
}

header .search-content .search .close-search {
  display: block;
  height: 48px;
  line-height: 48px;
  font-size: 1.8rem;
  margin-top: -24px;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 50%;
  width: 48px;
}

@media (min-width: 576px) {
  header .search-content .search input {
    font-size: 2rem;
  }
}
header .search-content .search-suggestions {
  margin: 0 auto 10px;
  max-width: 720px;
}

header .search-content .search-suggestions ul {
  list-style: none;
  margin: 0;
}

header .search-content .search-suggestions ul li a {
  color: #3d3d3d;
}

header .banner-content {
  padding: 14px 0 14px 45px;
  position: relative;
}

header .banner-content .h1, header .banner-content h1 {
  margin: 0;
}

header .banner-content .open-toc {
  color: #ffffff;
  display: block;
  font-size: 2.4rem;
  line-height: 48px;
  height: 48px;
  left: 10px;
  margin-top: -24px;
  position: absolute;
  text-align: center;
  text-decoration: none;
  top: 50%;
  width: 48px;
}

header .banner-content .open-toc:hover,
header .banner-content .open-toc:focus {
  color: #ffffff;
}

header .banner-content .open-toc i:last-child {
  display: none;
}

header .banner-content .open-toc.opened i:first-child {
  display: none;
}

header .banner-content .open-toc.opened i:last-child {
  display: inline;
}

@media (min-width: 1200px) {
  header .banner-content {
    padding-left: 0;
  }
  header .banner-content .open-toc {
    display: none;
  }
}
/*
====================================================================================================
Main
====================================================================================================
*/
main {
  position: relative;
}

main .content-inner {
  margin: 0 auto;
  padding: 20px 20px 0;
}

main .content-inner .crumbs {
  margin: 0 0 20px;
}

main .content-inner .page-tools {
  font-size: 1.6rem;
  margin: 0 0 20px;
}

@media (min-width: 1200px) {
  main .content-inner {
    padding: 20px 30px 0;
  }
}
/*
====================================================================================================
Footer
====================================================================================================
*/
footer {
  color: #ffffff;
  font-size: 1.5rem;
  line-height: 2.1rem;
}

footer p {
  margin: 0 0 15px;
}

footer ul {
  list-style: none;
  margin: 0;
}

footer a {
  color: #ffffff;
}

footer a:hover,
footer a:focus {
  color: #ffffff;
}

footer .footer-content {
  background: #1D1F29;
  padding: 40px 0 25px;
}

footer .footer-content:last-child {
  background: #12141a;
  padding: 50px 0 35px;
}

footer .links-toggle {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid #ffffff;
  display: block;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 15px 0;
  padding: 9px 40px 9px 15px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}

footer .links-toggle .icons {
  position: absolute;
  right: 15px;
  top: 9px;
}

footer .links-toggle .icons i:last-child {
  display: none;
}

footer .links-toggle.opened .icons i:first-child {
  display: none;
}

footer .links-toggle.opened .icons i:last-child {
  display: inline;
}

footer .links-wrap {
  display: none;
  margin: 0 0 15px;
  padding: 0 15px;
}

footer .logo {
  display: block;
  margin: 0 auto 30px;
  max-width: 240px;
}

footer .logo img {
  max-width: 100%;
}

footer .social {
  margin: -20px -5px 15px;
  text-align: center;
}

footer .social a {
  background: #ffffff;
  border-radius: 100%;
  color: #93A445;
  display: inline-block;
  font-size: 2rem;
  height: 36px;
  line-height: 3.6rem;
  margin: 5px;
  width: 36px;
}

footer .legal {
  margin: 0 0 15px;
  text-align: center;
}

footer .copyright {
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
}

@media (min-width: 576px) {
  footer .links-toggle {
    display: none;
  }
  footer .links-wrap {
    display: block !important;
    padding: 0;
  }
}
@media (min-width: 992px) {
  footer .logo {
    margin: 0;
  }
}
/*
====================================================================================================
Menu
====================================================================================================
*/
.menu.opened {
  right: 0;
  width: 100%;
}

.menu {
  background: #ffffff;
  border-left: 1px solid #cccccc;
  bottom: 0;
  max-width: 430px;
  overflow: auto;
  position: fixed;
  right: -430px;
  transition: right 0.2s ease-in-out;
  top: 65px;
  width: 0;
  z-index: 2;
}

.menu a {
  color: #3d3d3d;
}

.menu .form-search {
  margin: 0;
  padding: 0;
}

.menu .form-search input {
  background: whitesmoke;
  border-left: none;
  border-right: none;
  box-shadow: none;
}

.menu .main ul {
  font-size: 1.6rem;
  line-height: 2.2rem;
  list-style: none;
  margin: 0;
}

.menu .main ul > li {
  border-bottom: 1px solid #cccccc;
  margin: 0;
  overflow: hidden;
  position: relative;
}

.menu .main ul > li > a {
  display: block;
  padding: 8px 40px 8px 20px;
  transition: all 0.2s ease-in-out;
}

.menu .main ul > li > a:hover,
.menu .main ul > li > a:focus {
  background: #e0e0e0;
  text-decoration: none;
}

.menu .main ul > li > ul {
  margin: 0;
}

.menu .main ul > li > .toggle-sub-list {
  background: transparent;
  border: none;
  color: #3d3d3d;
  font-size: 2.2rem;
  outline: none;
  padding: 8px 5px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 32px;
}

.menu .main ul > li > .toggle-sub-list:hover,
.menu .main ul > li > .toggle-sub-list:focus {
  background: transparent;
}

.menu .main ul > li.opened > .toggle-sub-list {
  transform: rotate(90deg);
}

.menu .main ul > li > .sub-list ul li {
  border-bottom: none;
}

.menu .main ul > li > .sub-list ul li a {
  padding-left: 40px;
}

.menu .main ul > li > .sub-list ul li a:hover,
.menu .main ul > li > .sub-list ul li a:focus {
  text-decoration: none;
}

.menu .main ul > li > .sub-list .sub-list-item {
  border-top: 1px solid #cccccc;
}

.menu .main ul > li > .sub-list .sub-list-head {
  padding: 8px 40px 8px 20px;
  position: relative;
}

.menu .main ul > li > .sub-list .sub-list-head a {
  border: none;
  color: #93A445;
  outline: none;
  padding: 8px 5px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition: all 0.2s ease-in-out;
  width: 32px;
}

.menu .main ul > li > .sub-list .sub-list-head a.opened {
  transform: rotate(45deg);
}

.menu .foot ul {
  font-size: 1.4rem;
  line-height: 2rem;
  list-style: none;
  margin: 0;
  padding: 20px 0;
}

.menu .foot ul li {
  margin: 0;
}

.menu .foot ul li > a {
  display: block;
  padding: 5px 50px;
}

.menu .foot ul li > span {
  display: block;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 10px 50px;
  position: relative;
  text-transform: uppercase;
}

.menu .foot ul li > span i {
  font-size: 2rem;
  left: 15px;
  position: absolute;
  top: 10px;
}

/*
====================================================================================================
TOC
====================================================================================================
*/
.toc.opened {
  left: 0;
}

.toc {
  bottom: 0;
  left: -430px;
  max-width: 430px;
  position: fixed;
  top: 0;
  transition: left 0.2s ease-in-out;
  width: 100%;
  z-index: 3;
}

.toc .toc-inner {
  background: #ffffff;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}

.toc .toc-tabs {
  display: flex;
}

.toc .toc-tabs a {
  background: whitesmoke;
  color: #3d3d3d;
  flex: 1;
  font-weight: 500;
  line-height: 2.4rem;
  padding: 10px 5px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.toc .toc-tabs a .icon {
  color: #c8d396;
  display: none;
  font-size: 2.6rem;
}

.toc .toc-tabs a.on {
  background: #ffffff;
  color: #000000;
  font-weight: 700;
}

.toc .toc-tabs a.on:after {
  background: #93A445;
  bottom: 0;
  content: " ";
  height: 4px;
  left: 0;
  position: absolute;
  width: 100%;
}

.toc .heading {
  background: #e0e0e0;
  border-right: 1px solid #cccccc;
  color: #000000;
  display: none;
  font-weight: 500;
  line-height: 2.4rem;
  padding: 10px 50px 10px 20px;
  position: relative;
  text-transform: uppercase;
}

.toc .heading .close-toc {
  font-size: 2rem;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 44px;
}

.toc .form-search {
  margin: 10px 15px;
  padding: 0;
}

.toc .form-search input {
  background: #ffffff;
  box-shadow: none;
  height: 60px;
  padding: 14px 10px;
}

.toc .form-search button {
  height: 60px;
}

.toc .content .search-suggestions ul {
  border-top: none;
  margin: 0 0 20px;
}

.toc .content .search-suggestions ul > li > span,
.toc .content .search-suggestions ul > li > a {
  color: #3d3d3d;
  display: block;
  padding: 5px 15px;
}

.toc .content {
  padding: 0;
}

.toc .content > ul {
  border-top: 1px solid #cccccc;
}

.toc .content > ul > li {
  border-bottom: 1px solid #cccccc;
}

.toc .content ul {
  font-size: 1.6rem;
  line-height: 2.2rem;
  list-style: none;
  margin: 0;
}

.toc .content ul > li {
  margin: 0;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.toc .content ul > li > a {
  color: #3d3d3d;
  display: block;
  padding: 8px 20px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.toc .content ul > li > a:hover,
.toc .content ul > li > a:focus {
  background: whitesmoke;
}

.toc .content ul > li.parent > a {
  padding-right: 40px;
}

.toc .content ul > li.parent > .toggle {
  background: transparent;
  border: none;
  color: #3d3d3d;
  font-size: 2.2rem;
  outline: none;
  padding: 8px 5px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 32px;
}

.toc .content ul > li.parent > .toggle i {
  transition: all 0.2s ease-in-out;
}

.toc .content ul > li.parent.opened {
  background: #ffffff;
}

.toc .content ul > li.parent.opened > .toggle i {
  transform: rotate(90deg);
}

.toc .content ul > li > ul {
  background: transparent;
}

.toc .content ul > li > ul > li > a {
  padding-left: 40px;
}

.toc .content ul > li > ul > li > ul > li > a {
  padding-left: 60px;
}

.toc .content ul > li > ul > li > ul > li > ul > li > a {
  padding-left: 80px;
}

.toc .content ul > li > ul > li > ul > li > ul > li > ul > li > a {
  padding-left: 100px;
}

.toc .content .on > a {
  color: #636e2e;
}

.toc .content .on > a:hover,
.toc .content .on > a:focus {
  background: #ebebeb;
}

.toc .toc-content .content {
  background: #ffffff;
  border-right: 1px solid #cccccc;
  bottom: 0;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 44px;
  transition: all 0.2s ease-in-out;
}

@media (min-width: 1200px) {
  .toc {
    left: 0;
    max-width: 350px;
    padding-left: 56px;
    z-index: 1;
  }
  .toc .toc-inner {
    background: transparent;
    padding-left: 56px;
  }
  .toc .toc-tabs {
    background: #292929;
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: 56px;
    z-index: 1;
  }
  .toc .toc-tabs {
    display: block;
    text-align: center;
  }
  .toc .toc-tabs a:after {
    display: none;
  }
  .toc .toc-tabs a {
    background: transparent;
    display: inline-block;
    line-height: 44px;
    margin: 0 0 10px;
    outline: none;
    padding: 0;
    width: 44px;
  }
  .toc .toc-tabs a .icon {
    display: inline;
  }
  .toc .toc-tabs a .text {
    display: none;
  }
  .toc .toc-tabs a.on {
    background: transparent;
    color: #ffffff;
  }
  .toc .toc-tabs a.on .icon {
    color: #ffffff;
  }
  .toc .heading {
    display: block;
    border-bottom: 1px solid #cccccc;
  }
  .toc .toc-content .content {
    left: 56px;
    top: 45px;
  }
  .toc .content > ul {
    border-top: none;
  }
}
/*
====================================================================================================
Top
====================================================================================================
*/
.top-target {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 5px 0 0 5px;
  bottom: 100px;
  color: #ffffff;
  display: none;
  font-size: 2rem;
  height: 46px;
  line-height: 46px;
  position: fixed;
  right: 0;
  text-align: center;
  width: 46px;
  z-index: 9;
}

.top-target.fixed {
  display: none;
}

.top-target:hover,
.top-target:focus {
  color: #ffffff;
}

/*
====================================================================================================
Page Shield
====================================================================================================
*/
.page-shield {
  background: rgba(0, 0, 0, 0.7);
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

.accordions {
  margin: 0 0 40px;
}

.accordion {
  background: #ffffff;
  border: 1px solid #cccccc;
  margin: 0 0 10px;
}

.accordion .accordion-toggle {
  line-height: 2.4rem;
}

.accordion .accordion-toggle a {
  color: #292929;
  display: block;
  font-size: 1.8rem;
  padding: 10px 40px 10px 20px;
  position: relative;
  text-decoration: none;
}

.accordion .accordion-toggle .icon {
  color: #93A445;
  position: absolute;
  right: 20px;
  top: 10px;
  transition: all 0.2s ease-in-out;
}

.accordion .accordion-content {
  border-top: 1px solid #cccccc;
  display: none;
}

.accordion .accordion-content .content {
  font-size: 1.6rem;
  line-height: 2.6rem;
  max-width: 700px;
  padding: 20px;
}

.accordion .accordion-content .content p {
  margin: 0 0 10px;
}

.accordion .accordion-content .content ul {
  margin: 0 0 10px 30px;
}

.accordion.opened {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.accordion.opened .accordion-toggle {
  font-weight: 700;
}

.accordion.opened .accordion-toggle .icon {
  transform: rotate(45deg);
}

.bg-img {
  left: 0;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-img img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.bg-img .center-bottom {
  object-position: center bottom;
}

.bg-img .center-center {
  object-position: center center;
}

.bg-img .center-top {
  object-position: center top;
}

.bg-img .left-bottom {
  object-position: left bottom;
}

.bg-img .left-center {
  object-position: left center;
}

.bg-img .left-top {
  object-position: left top;
}

.bg-img .right-bottom {
  object-position: right bottom;
}

.bg-img .right-center {
  object-position: right center;
}

.bg-img .right-top {
  object-position: right top;
}

.body-copy {
  max-width: 700px;
}

.buttons-wrap {
  margin-left: -5px;
  margin-right: -5px;
}

.buttons-wrap > a {
  margin: 5px;
}

.card-wrap {
  height: 100%;
  padding: 0 0 30px;
}

.card-wrap .card-inner.clickable:hover,
.card-wrap .card-inner.clickable:focus {
  border-color: #c8d396 !important;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

.card-wrap .card-inner {
  background: #ffffff;
  border: 1px solid #cccccc;
  font-size: 1.6rem;
  height: 100%;
  line-height: 2.2rem;
  padding: 20px;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.card-wrap .content .h5,
.card-wrap .content h5 {
  font-weight: 500;
}

.card-wrap .content p {
  margin: 0 0 10px;
}

.card-wrap .content > p:last-child {
  margin: 0;
}

.card-wrap .content .head {
  background: #636e2e;
  color: #ffffff;
  margin: -21px -21px 15px;
  padding: 7px 20px;
}

.card-wrap .content .img-wrap {
  margin: -20px -20px 20px;
}

.card-wrap .content .date {
  background: transparent;
  border: none;
  margin: -10px 0 15px;
  padding: 0;
  width: 100%;
}

.card-wrap .content .date .time {
  margin: 10px 0 0;
  white-space: nowrap;
}

.card-wrap .content .text-cta i {
  top: 3px;
}

.code-bar {
  background: whitesmoke;
  border: 1px solid #cccccc;
  border-bottom: 0;
  font-family: "Roboto Mono", monospace;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 10px 15px;
}

.code-bar .buttons {
  font-size: 1.8rem;
  text-align: right;
}

.crumbs {
  color: #666666;
  display: none;
  font-size: 1.3rem;
  line-height: 1.8rem;
  margin: -20px 0 40px;
}

.crumbs a {
  display: inline-block;
  text-decoration: none;
}

.crumbs a:hover,
.crumbs a:focus {
  color: #000;
}

.crumbs span {
  display: inline-block;
  margin: 0 5px;
}

.crumbs .active {
  margin: 0;
}

@media (min-width: 576px) {
  .crumbs {
    display: block;
  }
}
.date {
  background: #ffffff;
  border: 1px solid #cccccc;
  margin: 0 0 20px;
  padding: 15px;
  width: 220px;
}

.date .day {
  font-size: 4.6rem;
  line-height: 4.6rem;
}

.date .month {
  white-space: nowrap;
}

.date .time {
  margin: 15px 0 0;
}

.docs-copy {
  max-width: 700px;
}

@media (min-width: 1200px) {
  .docs-copy {
    margin: 0 auto;
    max-width: 700px;
  }
}
.favorite-toggle {
  color: #58A7AF;
}

.favorite-toggle:hover,
.favorite-toggle:focus {
  text-decoration: none;
}

.favorite-toggle i:last-child {
  display: none;
}

.favorite-toggle.favorited i:first-child {
  display: none;
}

.favorite-toggle.favorited i:last-child {
  display: inline;
}

.filter-tags {
  margin: -5px -5px 20px;
}

.filter-tags a {
  border: 1px solid #cccccc;
  border-radius: 2px;
  color: #3d3d3d;
  display: inline-block;
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin: 5px;
  padding: 4px 35px 4px 10px;
  position: relative;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.filter-tags a i {
  color: #93A445;
  position: absolute;
  right: 10px;
  top: 9px;
}

.filter-tags a:hover,
.filter-tags a:focus {
  background: whitesmoke;
  border-color: #a3a3a3;
}

.form-filters {
  background: whitesmoke;
  margin: 0 0 30px;
  padding: 20px 20px 12px;
}

.form-filters p {
  margin: 0 0 8px;
}

.form-search {
  background: whitesmoke;
  margin: 0 0 30px;
  padding: 20px;
  position: relative;
}

.form-search form {
  position: relative;
}

.form-search form input {
  background: #ffffff;
  padding-left: 20px;
  padding-right: 44px;
}

.form-search form button {
  background: transparent;
  border: none;
  height: 44px;
  font-size: 1.6rem;
  line-height: 44px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
}

.form-search .toggles {
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin: 12px 0 -15px;
}

.form-search .toggles a:last-chidl {
  margin-right: 0;
}

.form-search .toggles a {
  color: #525252;
  display: inline-block;
  margin: 0 20px 10px 0;
  padding-left: 20px;
  position: relative;
  white-space: nowrap;
}

.form-search .toggles a:before {
  font-family: "Font Awesome 6 Free";
  content: "\f111";
  left: 0;
  position: absolute;
  top: 0;
}

.form-search .toggles a.checked:before {
  content: "\f192";
}

.form-search-type {
  margin: 0 0 30px;
  position: relative;
}

.form-search-type form {
  position: relative;
}

.form-search-type form input {
  background: #ffffff;
  height: 50px;
  padding: 9px 50px 9px 20px;
}

.form-search-type form button {
  background: transparent;
  border: none;
  color: #636e2e;
  height: 50px;
  font-size: 2.6rem;
  line-height: 50px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
}

.form-search-type .message {
  background: #f7f8f0;
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 12px 20px;
}

.form-search-type .message a:last-chidl {
  margin-right: 0;
}

.form-search-type .toggles {
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin: 12px 0 -15px;
}

.form-search-type .toggles a:last-chidl {
  margin-right: 0;
}

.form-search-type .toggles a {
  color: #525252;
  display: inline-block;
  margin: 0 20px 10px 0;
  padding-left: 20px;
  position: relative;
  white-space: nowrap;
}

.form-search-type .toggles a:before {
  font-family: "Font Awesome 6 Free";
  content: "\f111";
  left: 0;
  position: absolute;
  top: 0;
}

.form-search-type .toggles a.checked:before {
  content: "\f192";
}

.heading .h2, .heading h2 {
  font-size: 2.8rem;
  line-height: 3.2rem;
}

.img-wrap {
  position: relative;
}

.img-wrap img {
  max-width: 100%;
}

@media (min-width: 576px) {
  .img-float-left {
    float: left;
    margin-right: 30px;
    max-width: 50%;
  }
  .img-float-right {
    float: right;
    margin-left: 30px;
    max-width: 50%;
  }
}
.list-no-bull {
  list-style: none;
  margin: 0 0 40px;
}

.list-icons {
  list-style: none;
  margin: 0 0 40px;
}

.list-icons li {
  padding-left: 50px;
  position: relative;
}

.list-icons li > span:first-child {
  left: 20px;
  position: absolute;
  top: 0;
}

.listing {
  border-bottom: 1px solid #cccccc;
  margin: 0 0 30px;
  padding: 0 0 20px;
}

.listing p {
  margin: 0 0 10px;
}

.listing ul {
  margin-bottom: 10px;
}

.listing .content {
  max-width: 700px;
}

.listing .text-label {
  margin: 0 0 5px;
}

.listing .text-meta {
  margin: 0 0 5px;
}

.listing .text-title {
  font-size: 1.9rem;
  font-weight: 400;
  line-height: 3rem;
  margin: 0 0 10px;
}

.more-box {
  border: 1px solid #cccccc;
  margin: 0 0 10px;
  padding: 20px 20px 0px;
  position: relative;
}

.more-box .more-toggle {
  display: block;
  font-size: 2rem;
  height: 44px;
  line-height: 44px;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: -5px;
  width: 44px;
}

.more-box .more-toggle:after {
  content: "\f067";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
}

.more-box .more-toggle.opened:after {
  content: "\f068";
}

.more-box .content {
  position: relative;
}

.more-box .content .h3,
.more-box .content h3 {
  padding-right: 50px;
}

.more-box .row > div {
  position: static;
}

@media (min-width: 768px) {
  .more-box .content {
    position: static;
  }
  .more-box .more-toggle {
    right: 15px;
    top: 15px;
  }
}
.note-wrap {
  margin: 0 0 40px;
  overflow: hidden;
  padding-left: 5px;
}

.note-wrap .heading {
  background: rgba(255, 255, 255, 0.65);
  color: #000000;
  padding: 5px 20px 5px 35px;
  position: relative;
}

.note-wrap .heading .icon {
  left: 10px;
  position: absolute;
  top: 5px;
}

.note-wrap .content {
  background: rgba(255, 255, 255, 0.9);
  padding: 20px 20px 10px 35px;
}

.note-wrap .content p {
  margin: 0 0 10px;
}

.notice-wrap {
  border-radius: 4px;
  margin: 0 0 40px;
  overflow: hidden;
  padding: 2px;
}

.notice-wrap .notice-inner {
  padding: 10px 20px;
}

.page-links {
  position: relative;
  margin: 0;
}

.page-links:before {
  background: #cccccc;
  bottom: 15px;
  content: " ";
  position: absolute;
  top: 15px;
  width: 1px;
  z-index: 1;
}

.page-links ul {
  font-size: 1.4rem;
  line-height: 1.8rem;
  list-style: none;
  margin: 0;
}

.page-links ul li {
  margin: 0;
}

.page-links ul li a {
  color: #3d3d3d;
  display: block;
  padding: 5px 15px;
  position: relative;
  text-decoration: none;
}

.page-links ul li a:hover,
.page-links ul li a:focus {
  background: whitesmoke;
}

.page-links ul li ul li a {
  padding-left: 35px;
}

.page-links ul li ul li ul li a {
  padding-left: 55px;
}

.page-links ul li.active > a {
  color: #93A445;
  font-weight: 700;
}

.page-links ul li.active > a:after {
  background: #93A445;
  border-radius: 100%;
  content: " ";
  height: 8px;
  left: -3px;
  position: absolute;
  top: 11px;
  width: 8px;
  z-index: 2;
}

@media (min-width: 1200px) {
  .page-links {
    margin: 0 -30px 0 0;
  }
  .page-links {
    max-width: 260px;
  }
}
@media (min-width: 1500px) {
  .page-links {
    max-width: 350px;
  }
}
.page-links-dropdown {
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin: 0 0 30px;
  position: relative;
}

.page-links-dropdown .head {
  position: relative;
}

.page-links-dropdown .head a {
  border: 1px solid #93A445;
  color: #3d3d3d;
  display: block;
  font-weight: 700;
  padding: 12px 40px 12px 15px;
  text-decoration: none;
}

.page-links-dropdown .head .text i {
  font-weight: 900;
  margin-right: 5px;
}

.page-links-dropdown .head a .icons {
  color: #93A445;
  position: absolute;
  right: 20px;
  top: 13px;
}

.page-links-dropdown .head a .icons i:last-child {
  display: none;
}

.page-links-dropdown .head a.opened .icons i:first-child {
  display: none;
}

.page-links-dropdown .head a.opened .icons i:last-child {
  display: inline;
}

.page-links-dropdown > ul {
  background: #ffffff;
  border: 1px solid #93A445;
  border-top: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: none;
  font-size: 1.4rem;
  line-height: 2.2rem;
  list-style: none;
  left: 0;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1;
}

.page-links-dropdown > ul ul {
  list-style: none;
  margin: 0;
}

.page-links-dropdown ul li {
  margin: 0;
}

.page-links-dropdown ul li a {
  color: #3d3d3d;
  display: block;
  padding: 5px 15px;
  position: relative;
  text-decoration: none;
}

.page-links-dropdown ul li a:hover,
.page-links-dropdown ul li a:focus {
  background: whitesmoke;
}

.page-links-dropdown ul li ul li a {
  padding-left: 35px;
}

.page-links-dropdown ul li ul li ul li a {
  padding-left: 55px;
}

.page-tools {
  font-size: 1.8rem;
  line-height: 1.8rem;
  margin: -20px 0 40px;
}

.page-tools a {
  color: #3d3d3d;
  margin-right: 15px;
}

.page-tools a:last-child {
  margin-right: 0;
}

.pager {
  margin: 0 -3px 30px;
  text-align: center;
}

.pager nav a {
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 2px;
  color: #3d3d3d;
  display: inline-block;
  font-size: 1.4rem;
  line-height: 32px;
  margin: 2px 0;
  min-width: 34px;
  text-align: center;
  transition: all 0.2s ease-in-out;
}

.pager nav a:hover,
.pager nav a:focus {
  background: whitesmoke;
  border-color: #a3a3a3;
  text-decoration: none;
}

.pager nav a:focus {
  background: #93A445;
  color: #ffffff;
}

.pager nav span {
  display: inline-block;
  line-height: 32px;
  text-align: center;
  width: 34px;
}

.pager nav .on {
  background: #e0e0e0;
}

.pager nav .on:hover,
.pager nav .on:focus {
  background: #e0e0e0;
  color: #3d3d3d;
  text-decoration: none;
}

.pager-prev-next {
  font-size: 1.8rem;
  line-height: 2.4rem;
}

.pager-prev-next a {
  display: block;
  position: relative;
}

.pager-prev-next a i {
  margin-top: -9px;
  position: absolute;
  top: 50%;
  transition: all 0.2s ease-in-out;
}

.pager-prev-next a:hover,
.pager-prev-next a:focus {
  text-decoration: none;
}

.pager-prev-next .prev {
  padding-left: 20px;
  text-align: left;
}

.pager-prev-next .prev i {
  left: 0;
}

.pager-prev-next .prev:hover i,
.pager-prev-next .prev:focus i {
  left: -2px;
}

.pager-prev-next .next {
  padding-right: 20px;
  text-align: right;
}

.pager-prev-next .next i {
  right: 0;
}

.pager-prev-next .next:hover i,
.pager-prev-next .next:focus i {
  right: -2px;
}

.pdf-wrap {
  overflow: hidden;
  padding-bottom: 135%;
  position: relative;
}

.pdf-wrap object {
  border: none;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.side-filters {
  border: 1px solid #cccccc;
  margin: 0 0 30px;
}

.side-filters .toggle {
  background: #ffffff;
  color: #292929;
  display: block;
  line-height: 2.4rem;
  padding: 10px 40px 10px 20px;
  position: relative;
  text-decoration: none;
}

.side-filters .toggle .icon {
  color: #93A445;
  position: absolute;
  right: 15px;
  top: 10px;
}

.side-filters .toggle .icon {
  transition: all 0.2s ease-in-out;
}

.side-filters .toggle.opened .icon {
  transform: rotate(45deg);
}

.side-filters a {
  text-decoration: none;
}

.side-filters hr {
  margin: 0 0 15px;
}

.side-filters p {
  margin: 0 0 5px;
}

.side-filters ul {
  list-style: none;
  margin: 0 4px 4px;
}

.side-filters ul li {
  border-top: 1px solid #cccccc;
  margin: 0;
  position: relative;
}

.side-filters ul li a {
  color: #3d3d3d;
  display: block;
  line-height: 2rem;
  margin-right: 30px;
  padding: 5px 5px 5px 23px;
  position: relative;
}

.side-filters ul li a:before {
  color: #3d3d3d;
  font-family: "Font Awesome 6 Pro";
  font-size: 1.3rem;
  content: "\f0c8";
  left: 2px;
  position: absolute;
  top: 5px;
}

.side-filters ul li a.checked:before {
  content: "\f14a";
}

.side-filters ul li label {
  align-items: flex-start;
  display: flex;
  padding: 5px 0;
}

.side-filters ul li label input {
  height: auto;
  margin: 0 10px 0 0;
  box-shadow: none;
  padding: 0;
  position: relative;
  top: 3px;
  width: auto;
}

.side-filters ul li label .label-text {
  font-size: 1.4rem;
  margin: 0 30px 0 0;
}

.side-filters .content ul > li > ul > li > ul > li > ul > li > label {
  padding: 3px 0;
}

.side-filters .checklist-radios {
  border-top: 1px solid #cccccc;
  padding: 5px 0 10px;
}

.side-filters .checklist-radios li {
  border-top: none;
}

.side-filters .checklist-radios li a.radio {
  margin-right: 0;
}

.side-filters .checklist-radios li a.radio:before {
  content: "\f111";
}

.side-filters .checklist-radios li a.radio.checked:before {
  content: "\f192";
}

.side-filters ul li a.toggle-list:before {
  display: none;
}

.side-filters ul li a.toggle-list {
  color: #000000;
  font-size: 1.4rem;
  height: 30px;
  line-height: 32px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 30px;
}

.side-filters ul li a.toggle-list .icon {
  transition: all 0.2s ease-in-out;
}

.side-filters ul li a.toggle-list.opened .icon {
  display: block;
  transform: rotate(45deg);
}

.side-filters .content ul > li > ul {
  margin: 0 0 0 20px;
}

.side-filters .content ul > li > ul > li > ul > li > ul {
  margin-bottom: 8px;
}

.side-filters .content ul > li > ul > li > ul > li > ul > li {
  border-top: none;
}

.side-filters .content ul > li > ul > li > ul > li > ul > li > a {
  padding: 3px 5px 3px 23px;
}

.side-filters .content ul > li > ul > li > ul > li > ul > li > a:before {
  top: 3px;
}

.side-filters .side-filter {
  border-bottom: 3px solid #cccccc;
  margin: 0 0 15px;
}

.side-filters .toggle-filters a {
  color: #3d3d3d;
  display: block;
  line-height: 2.4rem;
  outline: none;
  padding: 5px 40px 5px 0;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}

.side-filters .toggle-filters .icon {
  color: #93A445;
  position: absolute;
  right: 11px;
  top: 5px;
}

.side-filters .toggle-filters .icon {
  transition: all 0.2s ease-in-out;
}

.side-filters .toggle-filters.opened .icon {
  transform: rotate(45deg);
}

.side-filters .content {
  border-top: 1px solid #cccccc;
  display: none;
  padding: 15px 20px 10px;
}

.side-filters .heading {
  margin: 0 0 5px;
}

@media (min-width: 768px) {
  .side-filters {
    border: none;
  }
  .side-filters .toggle {
    display: none;
    padding: 10px 0 0 2px;
    pointer-events: none;
  }
  .side-filters .toggle-filters a {
    display: block;
    padding-left: 2px;
  }
  .side-filters .content {
    border-top: none;
    display: block !important;
    padding: 0;
  }
  .side-filters .content ul {
    margin: 0 4px 4px;
  }
}
.slider-wrap {
  padding: 0 50px;
}

.slider-wrap .slick-slider {
  margin: 0 -15px 30px;
}

.slider-wrap .slick-slider .card-wrap {
  height: auto;
  padding: 0 15px;
}

.slider-wrap .slick-slider .img-wrap img {
  width: 100%;
}

.table-wrap {
  margin: 0 0 30px;
  overflow: auto;
}

.table-wrap table {
  margin: 0;
}

.table-responsive .inline-heading {
  display: none;
}

@media (max-width: 767px) {
  .table-responsive {
    border: 1px solid #e0e0e0;
    border-bottom: none;
  }
  .table-responsive thead {
    display: none;
  }
  .table-responsive tbody tr:nth-child(odd) td {
    background: #ffffff;
    border-bottom: 1px solid whitesmoke;
  }
  .table-responsive tbody tr:nth-child(even) td {
    background: whitesmoke;
    border-bottom: 1px solid #ffffff;
  }
  .table-responsive tbody tr td:last-child {
    border-bottom: 1px solid #e0e0e0;
  }
  .table-responsive tbody td {
    background: #ffffff;
    border: none;
    display: flex;
    justify-content: flex-start;
  }
  .table-responsive tbody td .inline-heading {
    display: flex;
    font-weight: 600;
    margin-right: 15px;
    min-width: 100px;
  }
}
.tabs {
  margin: 0 0 30px;
}

.tabs .tab-nav {
  display: none;
  margin-bottom: -1px;
  position: relative;
  z-index: 1;
}

.tabs .tab-nav a {
  align-items: center;
  background: whitesmoke;
  border: 1px solid #cccccc;
  color: #636e2e;
  display: flex;
  line-height: 2rem;
  margin-right: 5px;
  outline: none;
  padding: 10px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.tabs .tab-nav a:last-child {
  margin-right: 0;
}

.tabs .tab-nav a.on:after {
  background: #93A445;
  content: " ";
  height: 5px;
  left: -1px;
  position: absolute;
  right: -1px;
  top: -1px;
}

.tabs .tab-nav a.on,
.tabs .tab-nav a:hover,
.tabs .tab-nav a:focus {
  background: #ffffff;
  border-bottom-color: #ffffff;
  color: #3d3d3d;
  text-decoration: none;
}

.tabs .tab-content {
  background: #ffffff;
  border: 1px solid #cccccc;
}

.tabs .tab-content .tab-bar {
  background: whitesmoke;
  border-bottom: 1px solid #cccccc;
  color: #93A445;
  display: block;
  outline: none;
  padding: 10px 30px;
  position: relative;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.tabs .tab-content .tab-bar:last-of-type {
  border-bottom: 1px solid #ffffff;
}

.tabs .tab-content .tab-bar.on {
  background: #ffffff;
  border-bottom: 1px solid #cccccc;
  color: #3d3d3d;
}

.tabs .tab-content .tab-bar.on:after {
  background: #93A445;
  bottom: -1px;
  content: " ";
  left: -1px;
  position: absolute;
  top: -1px;
  width: 5px;
}

.tabs .tab-content .tab {
  padding: 30px 30px 15px;
}

.tabs .tab-content .tab p {
  margin: 0 0 15px;
}

@media (min-width: 992px) {
  .tabs .tab-nav {
    display: flex;
  }
  .tabs .tab-content .tab-bar {
    display: none;
  }
}
.tip-wrap {
  position: relative;
}

.tip-wrap .tip-text {
  border-bottom: 2px dotted #93A445;
  cursor: help;
}

.tip-wrap .tip-content {
  background: rgba(147, 164, 69, 0.8);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  border-left: 5px solid #93A445;
  bottom: 19px;
  display: none;
  color: #ffffff;
  font-size: 1.3rem;
  left: 50%;
  margin-left: -100px;
  line-height: 1.8rem;
  position: absolute;
  padding: 10px;
  width: 200px;
}

.tip-wrap:hover .tip-content,
.tip-wrap:focus .tip-content {
  display: block;
}

.vid-wrap {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
}

.vid-wrap iframe {
  border: none;
  bottom: 0;
  left: 0;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

/*
======================================================================================================
Tray Styles
======================================================================================================
*/
.tray {
  padding: 40px 0 10px;
  position: relative;
}

.tray .container {
  position: relative;
  z-index: 1;
}

.tray-alert {
  background: #000000;
  bottom: 0;
  color: #ffffff;
  font-size: 1.6rem;
  line-height: 2.2rem;
  padding: 24px 0;
  position: sticky;
  left: 0;
  width: 100%;
  z-index: 2;
}

.tray-alert p {
  margin: 0;
}

.tray-alert .container {
  position: relative;
}

.tray-alert .content {
  display: flex;
  padding-right: 40px;
}

.tray-alert .content .icon {
  font-size: 3rem;
  margin: 2px 15px 0 0;
}

.tray-alert .close-alert {
  background: transparent;
  border: none;
  color: #ffffff;
  display: inline-block;
  padding: 5px 10px;
  position: absolute;
  right: 20px;
  text-decoration: none;
  top: -3px;
}

.tray-banner {
  padding: 20px 0 30px;
}

.tray-banner .h1, .tray-banner h1 {
  margin: 0;
}

@media (min-width: 768px) {
  .tray-banner {
    padding: 22px 0 32px;
  }
  .tray-banner .h1, .tray-banner h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
}
.tray-vps .content {
  margin: 0 auto;
  max-width: 800px;
}

.tray-vps .form-search {
  background: transparent;
  margin: 0 auto 30px;
  max-width: 550px;
  padding: 0;
}

.tray-vps .form-search input::-moz-placeholder {
  opacity: 1;
}

.tray-vps .form-search input {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  height: 52px;
}

.tray-vps .form-search input:focus {
  background: rgb(255, 255, 255);
}

.tray-vps .form-search button {
  color: #636e2e;
  height: 52px;
}

@media (min-width: 768px) {
  .tray-vps h1, .tray-vps .h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
}
/*
====================================================================================================
Plugins
====================================================================================================
*/
/* Prism JS */
pre:before,
pre:after {
  display: none !important;
}

pre {
  margin: 0 0 30px !important;
}

pre > code {
  border-left-color: #636e2e !important;
}

/* Slick Slider */
.slick-slider {
  display: none !important;
}

.slick-slider.slick-initialized {
  display: block !important;
}

.slick-track {
  display: flex !important;
}

.slick-track .slick-slide {
  display: flex !important;
  height: auto !important;
}

.slick-arrow {
  background: #a3a3a3;
  border: none;
  cursor: pointer;
  height: 44px;
  line-height: 44px;
  margin-top: -22px;
  overflow: hidden;
  outline: none;
  padding: 0;
  position: absolute;
  text-indent: -999px;
  top: 50%;
  width: 36px;
  z-index: 1;
}

.slick-arrow:after {
  color: #ffffff;
  font-family: "Font Awesome 6 Free";
  font-size: 2rem;
  font-weight: 900;
  position: absolute;
  text-indent: 0;
}

.slick-arrow.slick-prev {
  left: -36px;
}

.slick-arrow.slick-prev:after {
  content: "\f053";
  left: 12px;
}

.slick-arrow.slick-next {
  right: -36px;
}

.slick-arrow.slick-next:after {
  content: "\f054";
  right: 12px;
}

.slick-arrow:focus {
  outline: 2px dotted #93A445;
}

.slick-dots {
  line-height: 20px;
  list-style: none;
  margin: 0;
  text-align: center;
  width: 100%;
  z-index: 1;
}

.slick-dots li {
  display: inline-block;
  margin: 0 5px 0;
}

.slick-dots li button {
  background: transparent;
  border: 2px solid #93A445;
  border-radius: 100%;
  cursor: pointer;
  height: 18px;
  overflow: hidden;
  padding: 0;
  text-indent: -1000px;
  width: 18px;
}

.slick-dots li.slick-active button {
  background: #93A445;
  border-color: #93A445;
}

/* Popups */
.mfp-bg {
  background: #000000;
  opacity: 0.5;
}

.white-popup {
  background: #ffffff;
  box-shadow: 2px 8px 16px 0 rgba(0, 0, 0, 0.25);
  font-size: 1.6rem;
  line-height: 2.6rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  position: relative;
}

.white-popup .mfp-close {
  height: 40px;
  font-size: 3rem;
  line-height: 4rem;
  opacity: 1;
  position: absolute;
  padding: 0;
  right: 0;
  text-align: center;
  top: 0;
  width: 40px;
}

.white-popup .mfp-close:hover,
.white-popup .mfp-close:focus {
  background: transparent;
}

.white-popup .content {
  padding: 30px;
}